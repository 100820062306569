<template>
  <div class="comment font-sm" :data-depth="depth" :class="{skeleton}">
    <div class="wrapper">
      <router-link :to="`/channel/${memberSeq}`" v-if="!(memberAuth && memberAuth.includes('ADMIN'))">
        <MemberPicture :memberSeq="memberSeq"/>
      </router-link>
      <MemberPicture :memberSeq="memberSeq" v-else/>
      <div class="root">
        <div class="profile">
          <span class="name" v-if="memberAuth && memberAuth.includes('ADMIN')">오마이컴퍼니</span>
          <span class="name" v-else-if="hideInvestorNameYn === 'Y'">키다리 후원자</span>
          <router-link :to="`/channel/${memberSeq}`" v-else>
            <span class="name">{{ memberName }}</span>
          </router-link>
          <span class="font-xs color-secondary d-block">{{ createDate }}</span>
          <span class="font-xs ml-1 color-secondary" v-if="page === 'cheer' && !state.isReply">{{ $lib.getNumberFormat(totalAmt) }}원 후원</span>
        </div>
        <div class="block flex-shrink-0" v-if="!state.isReply && page === 'cheer' && (state.isAdmin || state.isMaker)">
          <a class="font-xs pointer" @click="hide(idx)" title="차단하기" v-if="!commentBlindSeq || restoreFlag === 'Y'">
            <span>차단</span>
          </a>
          <a class="font-xs pointer" @click="restore(idx)" title="차단 해제하기" v-else>
            <span>차단 해제</span>
          </a>
        </div>
      </div>
      <div class="content">
        <span v-html="content"></span>
      </div>
      <div class="actions">
        <a class="color-secondary pointer" @click="reply(idx)" title="답변하기" v-if="!state.isReply && (state.isAdmin || state.isMaker) && restoreFlag !== 'N'">
          <span>답변하기</span>
        </a>
        <template v-if="(memberSeq && memberSeq === $store.state.account.memberSeq) && (page !== 'cheer' || page === 'cheer' && state.isReply)">
          <a class="color-secondary pointer" @click="edit(idx)" v-if="restoreFlag !== 'N'">
            <span>수정</span>
          </a>
          <a class="color-secondary pointer" @click="remove(idx)">
            <span>삭제</span>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentComment";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture},
  mixins: [mixin],
  props: {
    content: String,
    commentBlindSeq: Number,
    createDate: String,
    depth: Number,
    edit: Function,
    hideInvestorNameYn: String,
    hide: Function,
    idx: Number,
    memberSeq: String,
    memberName: String,
    memberAuth: String,
    openId: String,
    page: String,
    restore: Function,
    restoreFlag: String,
    remove: Function,
    reply: Function,
    skeleton: Boolean,
    totalAmt: Number,
  },
  setup(props) {
    const component = new Component(() => {
      state.isAdmin = store.state.account.memberAuth?.includes("ADMIN");
      state.isMaker = props.openId && props.openId === store.state.account.memberSeq;
      state.isReply = props.depth !== 0;
    });

    const state = reactive({
      isAdmin: false,
      isMaker: false,
      isReply: false,
    });

    return {
      component
      , state
    };
  }
});
</script>

<style lang="scss" scoped>
.comment {
  color: $colorTextDefault;
  margin-bottom: $px30;

  .wrapper {
    position: relative;
    padding-left: $px47;

    .img {
      width: $px35;
      height: $px35;
      position: absolute;
      top: $px3;
      left: 0;
      border: $px1 solid #eee;
      border-radius: 50%;
    }

    .root {
      display: flex;
      justify-content: space-between;
      gap: $px16;

      .profile {
        .name {
          font-size: $px13;
        }
      }

      .block {
        > a {
          background-color: $colorBackground;
          border-radius: $px100;
          padding: $px4 $px12;
          color: $colorSecondary;
        }
      }
    }

    .content {
      margin-top: $px5;
      word-break: break-all;
      white-space: pre-line;
    }

    .actions {
      margin-top: $px16;
      display: flex;
      gap: $px16;
    }
  }

  &[data-depth="1"] {
    padding-left: $px47;
    position: relative;

    &:before {
      content: " ";
      background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
      background-size: 100% 100%;
      width: $px20;
      height: $px20;
      position: absolute;
      top: $px5;
      left: $px15;
    }
  }

  &.skeleton {
    .wrapper {
      .img {
        @include skeleton;
      }

      .root {
        .profile {
          b, > span, a {
            @include skeleton;
          }
        }
      }

      .content > span {
        @include skeleton;
      }

      .actions > a {
        @include skeleton;
      }
    }
  }
}
</style>