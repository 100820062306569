<template>
  <div class="reward-order-form" :class="{skeleton : !state.loaded }">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="row">
        <div class="input col-lg-7">
          <div class="wrapper">
            <router-link :to="`/reward/${$route.params.projectSeq}`" class="back font-sm pointer" title="프로젝트 상세로 이동">
              <span class="img" style="background-image: url(/assets/ico/common.back.svg)"></span>
            </router-link>
            <router-link :to="`/reward/${$route.params.projectSeq}`" class="thumbnail img" title="프로젝트 조회" :style="{ 'background-image' : `url(${$definitions.urls.imgCdn + state.project.thumbNailPath})` }"></router-link>
            <header>
              <div class="title">
                <router-link :to="`/reward/${$route.params.projectSeq}`" title="프로젝트 조회">{{ state.project.projectName }}</router-link>
              </div>
              <div class="builder">
                <router-link :to="`/channel/${state.project.openId}`" class="font-sm">{{ state.project.builderName }}</router-link>
              </div>
            </header>
            <div class="parts">
              <div class="part" v-if="state.rewardItems[0].rewardSeq || !state.loaded">
                <div class="subject">
                  <span>선택 리워드</span>
                </div>
                <div class="content">
                  <div class="amount clearfix">
                    <div class="price">
                      <span>참여 금액:</span>
                      <b class="font-lg">{{ $lib.getNumberFormat(state.form.rewardItems[0].rewardAmt) }}</b>
                      <span>원</span>
                    </div>
                    <div class="count" v-show="!calendar.isAvailable()">
                      <label :for="`${component.name}RewardItemCnt`">수량: </label>
                      <div class="number-set">
                        <button class="minus btn btn-bordered-secondary" @click="setNumber('decrease')">
                          -
                        </button>
                        <Number :id="`${component.name}RewardItemCnt`" type="number" :value.sync="state.form.rewardItems[0].selectCount" :maxvalue="rewardCountMaxValue" className="border-focus-none"/>
                        <button class="plus btn btn-bordered-secondary" @click="setNumber('increase')">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="title font-sm">
                    <div v-html="state.rewardItems[0].title"></div>
                  </div>
                  <div class="reservation" v-if="calendar.isAvailable()">
                    <PickCalendar title="예약하기" :projectSeq="$route.params.projectSeq" :value.sync="calendar.dates.value"
                                  :selectableCnt="calendar.getReservationSelectableCnt()" :bordered="true" :loaded="state.loaded"/>
                  </div>
                  <div class="select" v-if="state.rewardItems[0].rewardSelFlag === 'Y'">
                    <div v-if="state.rewardItems[0].rewardOptionItems.length">
                      <label :for="`${component.name}RewardOptionItems`" class="font-sm">리워드 옵션</label>
                      <select :id="`${component.name}RewardOptionItems`" class="form-control border-focus-point font-sm" v-model="state.form.rewardItems[0].optionText" @change="changeRewardOptionItems($event)">
                        <option value="">{{ state.rewardItems[0].rewardSelText || "옵션을 선택해주세요." }}</option>
                        <!-- item.rewardOptionQty 값이 0이면 수량 제한이 없는 것임 -->
                        <template v-for="(item, idx) in state.rewardItems[0].rewardOptionItems">
                          <option :value="item.rewardOptionText" :key="idx" :disabled="item.rewardOptionQty !== '0' && item.extraCount <= 0" v-if="item.hideFlag !== 'Y'">
                            <template>{{ item.rewardOptionText }}</template>
                            <template v-if="item.rewardOptionQty !== '0'">(재고: {{ getExtraCount(item) }})</template>
                          </option>
                        </template>
                      </select>
                    </div>
                    <div v-else>
                      <label class="font-sm" :for="`${component.name}OptionText`">리워드 정보 입력</label>
                      <textarea type="text" :id="`${component.name}OptionText`" class="form-control border-focus-point font-sm" :placeholder="state.rewardItems[0].rewardSelText" v-model="state.form.rewardItems[0].optionText"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <div class="part amount">
                  <div class="subject">
                    <label :for="`${component.name}RewardAmt`">참여 금액</label>
                    <div class="desc">
                      <span>{{ $lib.getNumberFormat(minAmountWithoutReward) }}원 이상부터 가능합니다.</span>
                    </div>
                  </div>
                  <div class="side">
                    <Number :id="`${component.name}RewardAmt`" :placeholder="`ex) ${minAmountWithoutReward}`" :value.sync="state.form.rewardItems[0].rewardAmt" autocomplete="off"/>
                    <span>원</span>
                  </div>
                </div>
                <div class="part mobile">
                  <div class="subject">
                    <label :for="`${component.name}InvestorHp`">휴대폰 번호</label>
                    <div class="desc">
                      <span>결제(출금) 문자를 받으실 연락처를 입력해주세요.</span>
                    </div>
                  </div>
                  <div class="side">
                    <Phone :id="`${component.name}InvestorHp`" :value.sync="state.form.investorHp" placeholder="ex) 01012345678"/>
                  </div>
                </div>
              </template>
              <div class="part" v-if="state.rewardItems[0].rewardSeq && state.project.rewardAddJoinFlag === 'Y'">
                <div class="subject">
                  <label :for="`${component.name}AddAmt`">추가 참여금</label>
                  <span class="desc">참여금을 추가로 후원할 수 있습니다.(선택)</span>
                </div>
                <div class="content">
                  <Number :id="`${component.name}AddAmt`" :placeholder="`최소 ${$lib.getNumberFormat(minAddAmt)}원 이상 입력해주세요.`" :value.sync="state.form.addAmt" autocomplete="off"/>
                </div>
              </div>
              <div class="part coupon" v-if="state.coupons.length > 0">
                <div class="row">
                  <div :class="state.form.coupon ? 'col-lg-6' : 'col-lg-12'">
                    <div class="subject">
                      <label :for="`${component.name}Coupon`">나의 쿠폰</label>
                      <span class="desc">
                        <span class="color-point">{{ state.coupons.length }}개</span>
                      </span>
                    </div>
                    <div class="content">
                      <select :id="`${component.name}Coupon`" class="form-control border-focus-point font-sm" v-model="state.form.coupon">
                        <option :value="null">쿠폰을 선택해주세요.</option>
                        <option v-for="(c, idx) in state.coupons" :value="c" :key="idx">{{ c.couponName }}(사용 가능 금액: {{ $lib.getNumberFormat(c.couponPoint) }})</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 point" v-if="state.form.coupon">
                    <div class="select relative">
                      <div class="subject">
                        <label :for="`${component.name}Point`">금액 사용</label>
                      </div>
                      <div class="side font-xs form-check">
                        <input class="form-check-input" type="checkbox" :id="`${component.name}CheckCouponPointAll`" v-model="state.form.checkCouponPointAll" @change="changeCheckCouponPointAll()"
                               :disabled="!state.form.checkCouponPointAll && computedMaxCouponPoint === 0"/>
                        <label class="form-check-label" :for="`${component.name}CheckCouponPointAll`">전액 사용</label>
                      </div>
                      <Number :id="`${component.name}Point`" :placeholder="`최대 ${$lib.getNumberFormat(computedMaxCouponPoint)}원 사용 가능해요`" :value.sync="state.form.couponPoint" :maxvalue.sync="computedMaxCouponPoint"
                              :readonly="state.form.checkCouponPointAll" autocomplete="off"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="part point" v-if="state.cashPoint > 0">
                <div class="subject">
                  <label :for="`${component.name}Point`">나의 포인트</label>
                  <span class="desc">
                    <span class="color-point">{{ $lib.getNumberFormat(state.cashPoint) }}원</span>
                  </span>
                </div>
                <div class="side font-xs form-check">
                  <input class="form-check-input" type="checkbox" :id="`${component.name}CheckPointAll`" v-model="state.form.checkPointAll" @change="changeCheckPointAll()"
                         :disabled="!state.form.checkPointAll && computedMaxCashPoint === 0"/>
                  <label class="form-check-label" :for="`${component.name}CheckPointAll`">전액 사용</label>
                </div>
                <div class="content">
                  <Number :id="`${component.name}Point`" :placeholder="`최대 ${$lib.getNumberFormat(computedMaxCashPoint)}원까지 사용 가능해요`" :value.sync="state.form.cashPoint" :maxvalue.sync="computedMaxCashPoint"
                          :readonly="state.form.checkPointAll || computedMaxCashPoint === 0" autocomplete="off"/>
                </div>
              </div>
              <div class="part cheer">
                <div class="subject">
                  <label :for="`${component.name}CheerMessage`">응원 한마디</label>
                </div>
                <div class="beside recommend">
                  <button class="btn font-xs" @click="setChatGptCheerMessage($event)">
                    <span class="color-point">챗GPT</span>
                    <span>로 응원하기</span>
                  </button>
                </div>
                <div class="side font-xs form-check">
                  <input class="form-check-input" type="checkbox" :id="`${component.name}CheckAnonymous`" v-model="state.form.checkAnonymous"/>
                  <label class="form-check-label" :for="`${component.name}CheckAnonymous`">익명</label>
                </div>
                <div class="content">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div>
                        <span class="img profile" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>
                      </div>
                    </div>
                    <textarea :id="`${component.name}CheerMessage`" class="border-focus-point form-control font-sm" :placeholder="`ex) ${defaultSupportComment}`" v-model="state.form.supportComment"></textarea>
                  </div>
                </div>
              </div>
              <div class="part overseas d-flex align-items-start mb-3">
                <input type="checkbox" :id="`${component.name}CheckOverSeas`" v-model="state.overseas" @change="skipNiceAuthAndAddressVerify($event)"/>
                <label class="form-check-label pl-2" :for="`${component.name}CheckOverSeas`">
                  <span>해외 거주자</span>
                  <span class="font-sm color-secondary d-block pt-2">리워드 배송에 제한이 있을 수 있습니다.</span>
                </label>
              </div>
              <div class="part d-flex justify-content-between align-items-center" v-if="!state.niceAuth.passed">
                <div class="subject m-0">
                  <b class="font-sm">후원자 정보 인증</b>
                  <span class="color-secondary font-sm d-block">최초 1회만 인증</span>
                </div>
                <button class="btn btn-disabled font-sm" disabled v-if="state.niceAuth.passed">
                  인증 완료
                </button>
                <button :id="`${component.name}NiceAuth`" class="btn btn-bordered-point font-sm" @click="niceAuth()" v-else>휴대폰 인증</button>
              </div>
              <div class="part paid" v-if="state.rewardItems[0].rewardSeq">
                <div class="subject">
                  <label :for="`${component.name}InvestorHp`">
                    <span class="color-point">*</span>
                    <span> 결제자 연락처</span>
                  </label>
                </div>
                <div class="content">
                  <div class="form-group mb-0">
                    <Phone :id="`${component.name}InvestorHp`" :value.sync="state.form.investorHp" placeholder="ex) 01012345678"/>
                  </div>
                </div>
              </div>
              <div class="part receive" v-if="state.rewardItems[0].rewardSeq">
                <div class="subject">
                  <label :for="`${component.name}ReceiveName`">리워드 수령 정보</label>
                </div>
                <div class="side clearfix" v-if="state.project.skipAddressFlag !== 'Y'">
                  <button class="btn btn-default font-xs" @click="openShippingAddressModal()">배송지 변경</button>
                  <div class="font-xs">
                    <input class="form-check-input" type="checkbox" :id="`${component.name}ChkDefaultAddress`" v-model="state.form.checkDefaultAddress">
                    <label class="form-check-label" :for="`${component.name}ChkDefaultAddress`">기본 배송지로</label>
                  </div>
                </div>
                <div class="content">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label :for="`${component.name}ReceiveName`" class="subject font-sm">
                        <span class="color-point">*</span>
                        <span> 이름</span>
                      </label>
                      <input type="text" class="form-control border-focus-point font-sm" :id="`${component.name}ReceiveName`" placeholder="ex) 오마이" v-model="state.form.receiveName"/>
                    </div>
                    <div class="form-group col-md-6 hp">
                      <label class="subject font-sm" :for="`${component.name}Hp`">
                        <span class="color-point">*</span>
                        <span> 휴대전화</span>
                      </label>
                      <Phone :id="`${component.name}Hp`" :value.sync="state.form.hp" placeholder="ex) 01012345678"/>
                    </div>
                  </div>
                  <template v-if="state.project.skipAddressFlag !== 'Y'">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label :for="`${component.name}AddressName`" class="subject font-sm">
                          <span class="color-point" v-if="computedAddressRequired">* </span>
                          <span>배송지명</span>
                        </label>
                        <select :id="`${component.name}AddressName`" class="font-sm border-focus-point form-control" v-model="state.form.addressName" @change="onAddressSelectChange()">
                          <option :value="a" v-for="(a, idx) in state.address.list" :key="idx">{{ a }}</option>
                        </select>
                        <input :id="`${component.name}AddressNameInput`" type="text" class="form-control border-focus-point font-sm mt-1" placeholder="ex) 학교" v-model="state.address.input"
                               maxlength="15" v-if="state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1"/>
                      </div>
                      <div class="form-group col-md-6 memo">
                        <label :for="`${component.name}Memo`" class="subject font-sm">
                          <span>배송 메모</span>
                        </label>
                        <select :id="`${component.name}Memo`" class="font-sm border-focus-point form-control" v-model="state.form.memo" @change="onMemoSelectChange()">
                          <option :value="a" v-for="(a, idx) in state.memo.list" :key="idx">{{ a }}</option>
                        </select>
                        <input :id="`${component.name}MemoInput`" type="text" class="form-control border-focus-point font-sm mt-1" placeholder="ex) 문 앞에 두고 벨 눌러주세요." v-model="state.memo.input"
                               maxlength="50" v-if="state.memo.list.indexOf(state.form.memo) === state.memo.list.length - 1"/>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="relative">
                        <label class="subject font-sm" :for="`${component.name}Address`">
                          <span class="color-point" v-if="computedAddressRequired">* </span>
                          <span>주소</span>
                        </label>
                        <Address :id="`${component.name}Address`" :zoneCode.sync="state.form.postno" :address1.sync="state.form.addr1" :address2.sync="state.form.addr2" :disabled="!state.loaded"/>
                        <div class="default font-xs form-check">
                          <input class="form-check-input" type="checkbox" :id="`${component.name}ChkRegister`" v-model="state.form.checkRegister">
                          <label class="form-check-label" :for="`${component.name}ChkRegister`">회원 정보에 반영</label>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="form-group" v-if="state.rewardItems[0].nftUseYn === 'Y'">
                    <label :for="`${component.name}NftWalletAddress`" class="subject font-sm">
                      <span class="color-point">*</span>
                      <span> NFT 지갑 주소</span>
                    </label>
                    <div class="input-group font-sm">
                      <input type="text" class="form-control border-focus-point font-sm" :id="`${component.name}NftWalletAddress`" placeholder="인증 받기 버튼을 클릭해주세요." :value="state.nftWalletAddress" @click="openNftModal()" readonly/>
                      <div class="input-group-append">
                        <button class="btn btn-secondary font-xs pl-3 pr-3" @click="openNftModal()">인증 받기</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="part payment" v-if="!state.loaded || state.project.paymethods.map(m => m.name).includes(state.form.optPayment)">
                <div class="subject">
                  <span>결제 수단</span>
                  <span v-if="state.project.fundingType === 'A'">(예약 결제)</span>
                </div>
                <div class="content">
                  <ul class="tight no-scrollbar">
                    <li :class="{active: state.loaded && state.form.optPayment === p.name}" :data-pay="p.name" v-for="(p, idx) in state.project.paymethods" :key="idx">
                      <button class="btn" @click="selectPay(p.name)">
                        <span>
                          <span class="img" :style="{'background-image':`url(/assets/ico/${p.ico.on})`}"></span>
                          <span class="img off" :style="{'background-image':`url(/assets/ico/${p.ico.off})`}"></span>
                        </span>
                        <div class="font-sm">{{ p.title }}</div>
                      </button>
                    </li>
                  </ul>
                  <div class="pay" v-if="['card'].includes(state.form.optPayment)">
                    <CreditCard :removable="false" :temp="state.project.fundingType === 'K'" ref="creditCaredRef" v-if="state.form.optPayment === 'card'"/>
                  </div>
                </div>
              </div>
              <div class="part" v-if="false">
                <div class="subject">
                  <span>결제 정보 입력</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay col-lg-5">
          <div class="wrapper">
            <div class="outer">
              <div class="inner">
                <div class="details">
                  <table class="table">
                    <tbody>
                    <tr>
                      <th>
                        <span>후원금</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(computedCalcAmount) }}원</span>
                      </td>
                    </tr>
                    <tr v-if="state.project.deliveryAmt > 0">
                      <th>
                        <span>배송비</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.project.deliveryAmt) }}원</span>
                      </td>
                    </tr>
                    <tr v-if="state.form.addAmt > 0">
                      <th>
                        <span>추가 참여금</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.form.addAmt) }}원</span>
                      </td>
                    </tr>
                    <tr v-if="state.form.cashPoint > 0">
                      <th>
                        <span>나의 포인트</span>
                      </th>
                      <td>
                        <span>- {{ $lib.getNumberFormat(state.form.cashPoint) }}원</span>
                      </td>
                    </tr>
                    <tr class="point" v-if="state.form.couponPoint > 0">
                      <th>
                        <span>나의 쿠폰</span>
                      </th>
                      <td>
                      <span>
                        <span>{{ (state.form.couponPoint ? "-" : "") + $lib.getNumberFormat(state.form.couponPoint) }}원</span>
                      </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="subject clearfix">
                  <span class="float-left">최종 금액</span>
                  <span class="float-right">
                    <b class="color-point">{{ $lib.getNumberFormat(computedFinalAmount) }}</b>
                    <span class="color-point">원</span>
                  </span>
                </div>
              </div>
              <div class="guide font-sm">
                <p v-if="state.project.fundingType === 'K'">목표액 {{ $lib.getNumberFormat(state.project.targetAmt) }}원에 미달하여도 진행되는 프로젝트입니다. 결제는 프로젝트 후원 시 바로 진행됩니다.</p>
                <p v-else>프로젝트 성공 시, 결제는 {{ state.project.investEndDate }}에 진행됩니다. 목표금액에 미달될 경우 예약된 결제는 자동으로 취소됩니다.</p>
                <p class="keep-all">오마이컴퍼니는 플랫폼을 제공하는 중개자로 리워드 제공에 관한 지연, 제품의 하자 등으로 인한 일체의 법적 책임은 프로젝트 진행자에게 있습니다. 하지만 오마이컴퍼니는 진행자와 후원자간의 원활한 의사소통을 위해 최선의 노력을 다하겠습니다.</p>
                <div class="read form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check1`"/>
                    <label class="form-check-label" :for="`${component.name}Check1`">개인정보 제3자 제공 동의</label>
                    <a class="pointer-u float-right" @click="$store.commit('openModal', { name: 'Terms', params: { name: 'rewardPrivacy', size: 'md' } })">내용 보기</a>
                  </div>
                </div>
                <div class="read form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check2`"/>
                    <label class="form-check-label" :for="`${component.name}Check2`">펀딩 결제 시 유의사항 확인</label>
                    <a class="pointer-u float-right" @click="$store.commit('openModal', { name: 'Terms', params: { name: 'rewardNotice', size: 'md' } })">내용 보기</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="action">
              <button class="btn btn-block btn-point font-lg" @click="submit()">
                <span>후원하기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import env from "@/scripts/env";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import NoLogin from "@/components/NoLogin";
import CreditCard from "@/components/CreditCard";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import {httpError} from "@/scripts/httpError";
import {useGtm} from "@gtm-support/vue2-gtm";
import track from "@/scripts/track";
import PickCalendar from "@/components/PickCalendar";

function Component(initialize) {
  this.name = "pageRewardOrderForm";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {PickCalendar, CreditCard, NoLogin, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      state.project.paymethods = lib.getRenewed(payments);
      state.form.memo = state.memo.list[0];

      const promises = [
        http.get(`/api/reward/project/${projectSeq}/is-finished`).catch(httpError()),
        http.get(`/api/members/${store.state.account.memberSeq}/account`).catch(httpError()),
        http.get(`/api/reward/projects/${projectSeq}`).catch(httpError()),
        http.get(`/api/reward/projects/${projectSeq}/rewards`).catch(httpError()),
        http.get("/api/cash/sum").catch(httpError()),
        http.get(`/api/coupons/projects/${projectSeq}`).catch(httpError()),
        http.get(`/api/member/${store.state.account.memberSeq}/default-address`).catch(httpError()),
      ];

      state.loaded = false;
      const responses = await Promise.all(promises);
      const res1 = responses[0];
      const res2 = responses[1];
      const res3 = responses[2];
      const res4 = responses[3];
      const res5 = responses[4];
      const res6 = responses[5];
      const res7 = responses[6];
      state.loaded = true;

      if (res1?.error) {
        return router.push({path: `/reward/${projectSeq}`});
      } else if (res2?.error || res3?.error || res4?.error || res5?.error || res6?.error || res7?.error) {
        return;
      }

      if (res3.data.body.project.adultFlag === "Y") {
        const adultCertified = await http.get(`/api/members/${store.state.account.memberSeq}/adult`);
        adultCertified.data.body !== "Y" && store.commit("openModal", {
          name: "AdultCertification",
          routerReplace: true,
          preventClose: true,
          params: {
            projectType: "reward",
            projectSeq: res3.data.body.project.projectSeq
          },
          callback: `${component.name}.loadAccount`,
        });
      }

      const rewardItems = res4.data.body;
      const rewardItem = rewardItems.find(i => (i.rewardSeq ? i.rewardSeq.toString() : "") === (rewardSeq ? rewardSeq.toString() : ""));

      if (!rewardItem) {
        return router.replace({path: `/reward/${projectSeq}`}, () => {
          store.commit("setSwingMessage", "리워드 정보를 찾을 수 없습니다. 관리자에게 문의해주세요.");
        });
      }

      state.account = res2.data.body;
      state.niceAuth.passed = state.account.niceAuthFlag === "Y";
      state.nftWalletAddress = state.account.nftWalletAddress;
      state.form.investorHp = state.account.mobile;
      state.form.email = state.account.email;

      if (res7.data.body) {
        setFormAddress(res7.data.body);
      } else {
        state.form.checkDefaultAddress = true;
        state.form.addressName = state.address.list[0];
        state.form.receiveName = store.state.account.memberName;
        state.form.hp = store.state.account.mobile;
      }

      state.project = res3.data.body.project;

      state.project.paymethods = [];

      if (state.project.paymethod) {
        const payMethods = state.project.paymethod.split(",");

        for (let p of payments) {
          if (payMethods.find(name => p.name === name)) {
            state.project.paymethods.push(p);
          }
        }
      }

      if (Array.isArray(res3.data.body.thumbNailList) && res3.data.body.thumbNailList.length) {
        state.project.thumbNailPath = res3.data.body.thumbNailList[0]?.thumbFilePath;
      }

      state.rewardItems = [rewardItem];
      rewardCountMaxValue = state.rewardItems[0].extraCount <= 0 ? 500 : state.rewardItems[0].extraCount;
      state.project.deliveryAmt = state.rewardItems[0].rewardSeq ? window.Number(state.project.deliveryAmt) : 0;

      state.form.coupon = null;
      state.form.rewardItems = [];

      for (let i = 0; i < state.rewardItems.length; i += 1) {
        state.form.rewardItems.push({
          rewardSeq: state.rewardItems[i].rewardSeq,
          selectCount: 1,
          optionText: "",
          rewardAmt: state.rewardItems[i].rewardAmt
        });
      }

      state.cashPoint = res5.data.body;
      state.coupons = res6.data.body;
      state.project.paymethods.length && selectPay(state.project.paymethods[0].name);

      track.post({
        name: "rewardProjectOrderForm",
        category: "후원하기",
        topic: "후원 정보 입력",
        title: state.project.projectName,
      });

      // GTM으로 이벤트 전달
      const loggingMsg = "Event rewardProject InitiateCheckout";
      try {
        const gtm = useGtm();
        if (gtm.enabled()) {
          let dataLayerProjectName = state.project.projectName;
          // 후원형인 경우 선택된 리워드의 단위 금액을 상품금액으로 전달
          let dataLayerAmount = (state.rewardItems.length > 0 ? state.rewardItems[0].rewardAmt : 0);
          let dataLayerEvent = "EventRewardProjectInitiateCheckout";

          window.dataLayer?.push({
            event: dataLayerEvent,
            "project.projectSeq": projectSeq,
            "project.projectName": dataLayerProjectName,
            "amount": dataLayerAmount,
          });
        }
      } catch (err) {
        // 데이터 전송이 실패하면 콘솔로그출력
        console.warn(`Failed ${loggingMsg} - ${err}`);
      }

      state.form.checkDefaultAddress = state.project.skipAddressFlag !== "Y";
      state.form.checkRegister = state.project.skipAddressFlag !== "Y";

      http.get("/api/investor/auth-module").then(({data}) => {
        state.niceAuth.encodeData = data.body.sEncData;
      });
    });

    const state = reactive({
      loaded: true,
      overseas: false,
      niceAuth: {
        encodeData: null,
        passed: false,
      },
      account: {
        memberName: "",
        memberAddr1: "",
        memberAddr2: "",
        memberPost: "",
        email: "",
        mobile: "",
        nftWalletAddress: "",
        niceAuthFlag: "N"
      },
      nftWalletAddress: "",
      project: {
        adultFlag: "N",
        projectSeq: null,
        projectName: "Wait a moment",
        builderName: "Wait a moment",
        deliveryAmt: 0,
        thumbNailPath: "",
        rewardAddJoinFlag: "",
        paymethod: "",
        paymethods: [],
      },
      cashPoint: 0,
      coupons: [],
      rewardItems: [{
        rewardSeq: "",
        rewardAmt: "",
        title: "",
        rewardSelFlag: "",
        rewardOptionItems: [],
        rewardSelText: "",
      }],
      address: {
        input: "",
        list: ["집", "회사", "가족", "친구", "직접 입력"],
      },
      memo: {
        input: "",
        list: ["배송 전 미리 연락주세요.", "부재 시 문 앞에 놓아주세요.", "부재 시 경비실에 맡겨 주세요.", "부재 시 전화나 문자 남겨 주세요.", "직접 입력"],
      },
      form: {
        receiveName: "",
        addressSeq: "",
        addressName: "",
        email: "",
        hp: "",
        investorHp: "",
        postno: "",
        addr1: "",
        addr2: "",
        coupon: {
          couponName: "",
          couponPoint: 0,
        },
        couponPoint: 0,
        cashPoint: 0,
        expenseAmt: 0,
        addAmt: 0,
        optPayment: "",
        memo: "",
        supportComment: "",
        checkAnonymous: false,
        checkPointAll: false,
        checkCouponPointAll: false,
        checkRegister: false,
        checkDefaultAddress: false,
        rewardItems: [{
          rewardSeq: "",
          selectCount: 1,
          optionText: "",
          rewardAmt: 0
        }],
      },
    });

    const creditCaredRef = ref();
    const projectSeq = router.app.$route.params.projectSeq;
    const rewardSeq = router.app.$route.query.rewardSeq;
    const defaultSupportComment = "응원합니다.";
    let prevPayMethod, merchantUid;
    let rewardCountMaxValue;

    let minAddAmt = 1000; // 최소 추가 참여금
    let maxAddAmt = 1000000; // 최소 추가 참여금
    let minAmountWithoutReward = 1000; // 최소 리워드 없이 참여금

    const calendar = {
      // 예약한 일자 값
      dates: ref([]),

      // 예약 가능 여부
      isAvailable() {
        return window.Number(projectSeq) === definitions.pickCalendar.projectSeq;
      },

      // 선택 가능한 일자 수(리워드 제목에서 가장 큰 수 가져오기, 숫자가 없으면 제한 없음)
      getReservationSelectableCnt() {
        const title = state.rewardItems[0].title;

        if (/\d/.test(title)) {
          const numbers = state.rewardItems[0].title.replace(/\D+/g, " ").trim().split(" ").map(n => parseInt(n));
          return Math.max(...numbers);
        }

        return definitions.pickCalendar.unlimitedNum;
      }
    };

    const computedCalcAmount = computed(() => state.form.rewardItems[0].rewardAmt * state.form.rewardItems[0].selectCount);
    const computedTotalAmount = computed(() => computedCalcAmount.value + state.project.deliveryAmt + state.form.addAmt);

    const computedMaxCashPoint = computed(() => {
      const baseAmount = computedTotalAmount.value - state.form.couponPoint;
      return state.cashPoint > baseAmount ? baseAmount : state.cashPoint;
    });

    const computedMaxCouponPoint = computed(() => {
      const baseAmount = computedTotalAmount.value - state.form.cashPoint;
      return state.form.coupon.couponPoint > baseAmount ? baseAmount : state.form.coupon.couponPoint;
    });

    const computedFinalAmount = computed(() => {
      return computedTotalAmount.value - state.form.couponPoint - state.form.cashPoint;
    });

    const computedAddressRequired = computed(() => {
      return !state.overseas;
    });

    const payments = [{
      name: "naverpay",
      title: "네이버페이",
      ico: {
        on: "page.order.reward.naverpay.active.svg",
        off: "page.order.reward.naverpay.svg"
      }
    }, {
      name: "card",
      title: "카드",
      ico: {
        on: "page.order.reward.card.active.svg",
        off: "page.order.reward.card.svg"
      }
    }, {
      name: "virtual",
      title: "가상계좌",
      ico: {
        on: "page.order.reward.virtual.active.svg",
        off: "page.order.reward.virtual.svg"
      }
    }];

    const loadAccount = async () => {
      const res = await http.get(`/api/members/${store.state.account.memberSeq}/account`).catch(httpError());

      state.account = res.data.body;
      state.niceAuth.passed = state.account.niceAuthFlag === "Y";
    };

    const openShippingAddressModal = () => {
      store.commit("openModal", {
        name: "ShippingAddress",
        params: {
          name: "ShippingAddress",
          size: "md",
        },
        callback: `${component.name}.onShippingAddressSelect`
      });
    };

    const onShippingAddressSelect = (address) => {
      setFormAddress(address);
    };

    const onAddressSelectChange = () => {
      if (state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1) {
        state.address.input = "";
        nextTick(() => {
          document.getElementById(`${component.name}AddressNameInput`)?.focus();
        });
      }
    };

    const onMemoSelectChange = () => {
      if (state.memo.list.indexOf(state.form.memo) === state.memo.list.length - 1) {
        state.memo.input = "";
        nextTick(() => {
          document.getElementById(`${component.name}MemoInput`)?.focus();
        });
      }
    };

    const selectPay = (payName) => {
      state.form.optPayment = payName;
    };

    const changeCheckPointAll = () => {
      if (state.form.checkPointAll) {
        state.form.cashPoint = computedMaxCashPoint.value;
      } else {
        state.form.cashPoint = 0;
      }
    };

    const changeCheckCouponPointAll = () => {
      if (state.form.checkCouponPointAll) {
        state.form.couponPoint = computedMaxCouponPoint.value;
      } else {
        state.form.couponPoint = 0;
      }
    };

    const changeRewardOptionItems = (event) => {
      if (event.target.selectedIndex === 0) {
        return;
      }

      state.form.rewardItems[0].selectCount = 1;
      let rewardExtraCnt = state.rewardItems[0].extraCount;
      let rewardOptionExtraCnt = state.rewardItems[0].rewardOptionItems[event.target.selectedIndex - 1].extraCount;

      // 1) 리워드의 수량이 있고 && 리워드 옵션의 수량이 없는 경우 -> 리워드의 남은 수량
      // 2) 리워드 옵션의 수량이 없는 경우 -> 500
      // 3) 리워드 옵션의 수량이 있는 경우 -> 리워드 옵션의 남은 수량
      if (rewardExtraCnt > 0 && rewardOptionExtraCnt <= 0) {
        rewardCountMaxValue = rewardExtraCnt;
      } else if (rewardOptionExtraCnt <= 0) {
        rewardCountMaxValue = 500;
      } else {
        rewardCountMaxValue = rewardOptionExtraCnt;
      }
    };

    const openNftModal = () => {
      store.commit("openModal", {
        name: "Nft",
        callback: `${component.name}.setNftWalletAddress`
      });
    };

    const setChatGptCheerMessage = () => {
      http.post("/api/chatgpt", {topic: "cheer", prompt: `프로젝트 진행자님에게 응원 한 마디 해주세요. 50 byte 이하로. 이모지 없이. 존댓말로.`}).then(({data}) => {
        if (data.code === "200 OK") {
          state.form.supportComment = data.body || defaultSupportComment;
          document.getElementById(`${component.name}ChatGptBtn`)?.setAttribute("disabled", true);
        } else {
          store.commit("setSwingMessage", data.message);
        }
      });
    };

    const setFormAddress = (address) => {
      state.form.addressSeq = address.addressSeq;
      state.form.addressName = address.addressName;
      state.form.receiveName = address.name;
      state.form.hp = address.mobile;
      state.form.postno = address.post;
      state.form.addr1 = address.addr1;
      state.form.addr2 = address.addr2;
      state.form.checkDefaultAddress = address.defaultFlag === "Y";

      if (!state.address.list.includes(state.form.addressName)) {
        state.address.input = state.form.addressName;
        state.form.addressName = state.address.list[state.address.list.length - 1];
      }
    };

    const setNftWalletAddress = (nftWalletAddress) => {
      state.nftWalletAddress = nftWalletAddress;
    };

    const setNumber = (type) => {
      if (!state.form.rewardItems[0].selectCount && type === "decrease") {
        return;
      }

      if (type === "increase" && state.form.rewardItems[0].selectCount < rewardCountMaxValue) {
        state.form.rewardItems[0].selectCount += 1;
      } else if (type === "decrease" && state.form.rewardItems[0].selectCount > 1) {
        state.form.rewardItems[0].selectCount -= 1;
      }
    };

    // 유효성 검증
    const validate = async () => {
      const warn = (message, id) => {
        document.getElementById(id)?.focus();
        store.commit("setSwingMessage", message);
        return false;
      };

      if (state.rewardItems[0].rewardSeq) {
        if (calendar.isAvailable()) {
          const selectableCnt = calendar.getReservationSelectableCnt();

          if (!selectableCnt) {
            store.commit("setSwingMessage", definitions.messages.errorInquire);
            return false;
          }

          if (!calendar.dates.value.length) {
            store.commit("setSwingMessage", "달력에서 예약 가능한 일자를 선택해주세요.");
            return false;
          } else if (calendar.dates.value.length > selectableCnt) {
            store.commit("setSwingMessage", `선택 가능한 일자(${selectableCnt}일)를 초과하였습니다.`);
            return false;
          }

          // else if (calendar.dates.value.length < selectableCnt) {
          //   store.commit("setSwingMessage", `예약 가능한 일자(${selectableCnt}일)를 모두 선택해주세요.`);
          //   return false;
          // }

          const res = await http.get(`/api/reward/projects/${state.project.projectSeq}/pick-calendar-reserved-dates`).catch(httpError());

          if (res?.error) {
            return false;
          }

          const reservedDates = res.data.body;

          for (let d of calendar.dates.value) {
            if (reservedDates[d] >= definitions.pickCalendar.reservableCntPerDay) {
              store.commit("setSwingMessage", `선택하신 일자(${d})는 이미 예약이 완료되었습니다. 다른 일자를 선택해주세요.`);
              return false;
            }
          }

          if (selectableCnt !== definitions.pickCalendar.unlimitedNum && calendar.dates.value.length < selectableCnt && !confirm(`예약 가능한 일(${selectableCnt}일)보다 적게(${calendar.dates.value.length}일) 선택하셨습니다. 진행하시겠습니까?`)) {
            return false;
          }
        }

        const rewardItemCnt = window.Number(state.form.rewardItems[0].selectCount);

        if (state.project.adultFlag !== "Y" && !state.niceAuth.passed) {
          document.getElementById(`${component.name}NiceAuth`)?.focus();
          return window.alert("후원자 정보 인증 메뉴에서 휴대폰 인증을 진행해주세요.");
        }

        if (!rewardItemCnt) {
          return warn("리워드 수량을 입력해주세요.", `${component.name}RewardItemCnt`);
        } else if (rewardItemCnt < 0) {
          return warn("리워드 수량을 정확하게 입력해주세요.", `${component.name}RewardItemCnt`);
        } else if (rewardItemCnt > rewardCountMaxValue && rewardCountMaxValue > 0) {
          state.form.rewardItems[0].selectCount = rewardCountMaxValue;
          return warn(`리워드 수량은 ${rewardCountMaxValue}개 이하로 입력해주세요.`, `${component.name}RewardItemCnt`);
        } else if (state.rewardItems[0].rewardSelFlag === "Y") {
          if (state.rewardItems[0].rewardOptionItems.length) {
            if (!state.form.rewardItems[0].optionText) {
              return warn("리워드 옵션을 선택해주세요.", `${component.name}RewardOptionItems`);
            }
          } else if (!state.form.rewardItems[0].optionText) {
            return warn("리워드 옵션을 입력해주세요.", `${component.name}OptionText`);
          } else if (state.form.rewardItems[0].optionText.length > 100) {
            return warn("리워드 옵션을 100자 이하로 입력해주세요.", `${component.name}OptionText`);
          }
        }
      } else if (!state.form.rewardItems[0].rewardAmt || state.form.rewardItems[0].rewardAmt < minAmountWithoutReward) {
        return warn(`참여 금액을 ${lib.getNumberFormat(minAmountWithoutReward)}원 이상 입력해주세요.`, `${component.name}RewardAmt`);
      }

      const addAmt = document.getElementById(`${component.name}AddAmt`)?.value;

      if (addAmt && !lib.isNumeric(lib.replaceAll(addAmt, ",", ""))) {
        return warn("추가 참여금을 숫자로 입력해주세요.", `${component.name}AddAmt`);
      }

      if (typeof state.form.addAmt === "number" && state.form.addAmt !== 0) {
        if (state.form.addAmt < minAddAmt) {
          return warn(`참여금 추가 입력은 ${lib.getNumberFormat(minAddAmt)}원 이상 입력해주세요.`, `${component.name}AddAmt`);
        } else if (state.form.addAmt >= maxAddAmt) {
          return warn(`참여금 추가 입력은 ${lib.getNumberFormat(maxAddAmt)}원 미만으로 입력해주세요.`, `${component.name}AddAmt`);
        }
      }

      if (typeof state.form.cashPoint === "number" && state.form.cashPoint !== 0 && state.form.cashPoint < 100) {
        return warn("포인트는 100원 이상 입력해주세요.", `${component.name}Point`);
      }

      if (state.form.supportComment && lib.getBytes(state.form.supportComment) > 750) {
        return warn("응원 한마디의 내용이 너무 깁니다. 내용의 길이를 줄여주세요.", `${component.name}CheerMessage`);
      }

      if (state.rewardItems[0].rewardSeq && !state.form.receiveName) {
        return warn("리워드를 받으실 분의 이름을 입력해주세요.", `${component.name}ReceiveName`);
      }

      if (!state.form.investorHp) {
        return warn("결제자 연락처를 입력해주세요.", `${component.name}InvestorHp`);
      } else if (!lib.isValidPhoneNum(state.form.investorHp)) {
        return warn("결제자 연락처 형식이 올바르지 않습니다.", `${component.name}InvestorHp`);
      }

      if (state.rewardItems[0].rewardSeq) {
        if (!state.form.hp) {
          return warn("리워드를 받으실 분의 휴대전화 번호를 입력해주세요.", `${component.name}Hp`);
        } else if (!lib.isValidPhoneNum(state.form.hp)) {
          return warn("리워드를 받으실 분의 휴대전화 번호 형식이 올바르지 않습니다.", `${component.name}Hp`);
        }

        // #1088 배송지 주소 SKIP 설정이 돼있다면 제출 시 주소 값 초기화
        if (state.project.skipAddressFlag === "Y" || state.overseas) {
          state.form.postno = "";
          state.form.addr1 = "";
          state.form.addr2 = "";
        } else {
          if (state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1 && !state.address.input.trim()) {
            return warn("리워드의 배송지명을 입력해주세요.", `${component.name}AddressNameInput`);
          } else if (state.rewardItems[0].rewardSeq && (!state.form.addr1 || !state.form.addr2 || !state.form.postno)) {
            return warn("리워드를 받으실 주소를 입력해주세요.", `${component.name}Address`);
          }
        }

        if (state.rewardItems[0].nftUseYn === "Y" && !state.nftWalletAddress?.trim()) {
          return warn("NFT 지갑 주소를 입력해주세요.", `${component.name}NftWalletAddress`);
        }
      }

      if (!state.form.optPayment) {
        return store.commit("setSwingMessage", "결제 수단을 선택해주세요.");
      } else if (state.form.optPayment === "card" && !creditCaredRef.value?.state.card.cardNum && !creditCaredRef.value?.state.form.cardNumber) {
        return store.commit("setSwingMessage", "결제하실 카드 정보를 입력해주세요.");
      } else if (!document.getElementById(`${component.name}Check1`).checked) {
        return store.commit("setSwingMessage", "개인정보 제3자 제공 동의가 필요합니다.");
      } else if (!document.getElementById(`${component.name}Check2`).checked) {
        return store.commit("setSwingMessage", "펀딩 결제 시 유의사항 확인 후 체크해주세요.");
      }

      if (computedFinalAmount.value < 0) {
        return store.commit("setSwingMessage", "결제 금액은 음수가 될 수 없습니다. 관리자에게 문의해주세요.");
      } else if (state.form.optPayment === "naverpay" && computedFinalAmount.value > 0 && computedFinalAmount.value < 100) {
        return store.commit("setSwingMessage", "선택하신 결제 수단의 최소 결제 금액은 100원입니다.");
      }

      return true;
    };

    // 배송지 등록
    const postAddress = async () => {
      if (state.project.skipAddressFlag === "Y") {
        return true;
      }

      const addressName = state.address.list.indexOf(state.form.addressName) === state.address.list.length - 1 ? state.address.input.trim() : state.form.addressName;
      const args = {
        addressSeq: state.form.addressSeq,
        addressName: addressName,
        name: state.form.receiveName,
        mobile: state.form.hp,
        post: state.form.postno,
        addr1: state.form.addr1,
        addr2: state.form.addr2,
        defaultFlag: state.form.checkDefaultAddress ? "Y" : "N"
      };

      // 배송지 추가
      const res1 = await http.post(`/api/member/${store.state.account.memberSeq}/address`, args).catch(httpError());

      if (res1?.error) {
        return false;
      }

      if (!state.form.addressSeq) {
        return true;
      }

      // 배송지 사용 날짜 업데이트
      const res2 = await http.put(`/api/member/${store.state.account.memberSeq}/address/${state.form.addressSeq}/use`);
      return !res2?.error;
    };

    // NFT 업데이트
    const putNftWalletAddress = async () => {
      if (state.rewardItems[0].nftUseYn === "Y" && state.nftWalletAddress !== state.account.nftWalletAddress) {
        const nftWalletAddress = state.nftWalletAddress;
        const res = await http.put(`/api/members/${store.state.account.memberSeq}/account/nft-address`, {nftWalletAddress}).catch(httpError());

        if (res?.error) {
          return false;
        }
      }

      return true;
    };

    // 임시 테이블 저장
    const putInvestTmp = (func) => {
      const memo = state.memo.list.indexOf(state.form.memo) === state.memo.list.length - 1 ? state.memo.input : state.form.memo;
      const args = {
        rewardItemList: [],
        rewardInvestor: {
          projectSeq: state.project.projectSeq,
          investType: state.project.simulationFlag === "Y" ? "모의투자" : "투자",
          orderno: merchantUid,
          receiveName: state.form.receiveName,
          email: state.form.email,
          hp: state.form.hp.replaceAll("-", ""),
          investorHp: state.form.investorHp.replaceAll("-", ""),
          supportComment: state.form.supportComment?.trim() ? state.form.supportComment.trim() : defaultSupportComment,
          addr1: state.form.addr1,
          addr2: state.form.addr2,
          postno: state.form.postno,
          memo: memo,
          expenseAmt: computedTotalAmount.value,
          paymethod: state.form.optPayment,
          addAmt: state.form.addAmt || 0,
          omcpay: state.form.cashPoint || 0,
          couponId: state.form.coupon?.couponId || "",
          couponPoint: state.form.couponPoint || 0,
          hideInvestorNameYn: state.form.checkAnonymous ? "Y" : "N",
          isSaveMyInfo: state.form.checkRegister ? "Y" : "N",
        }
      };

      if (state.rewardItems[0].rewardSeq) {
        args.rewardItemList = lib.getRenewed(state.form.rewardItems);

        if (calendar.isAvailable() && args.rewardItemList.length) {
          args.rewardItemList[0].optionText = window.JSON.stringify(calendar.dates.value);
        }
      } else {
        args.rewardInvestor.expenseAmt = state.form.rewardItems[0].rewardAmt;
      }

      http.put(`/api/reward/project/${state.project.projectSeq}/invest-tmp`, args).then(func);
    };

    const postCardOrder = () => {
      let url;

      const args2 = {
        buyerAddr1: state.form.addr1,
        buyerAddr2: state.form.addr2,
        buyerEmail: state.account.email,
        buyerName: state.account.memberName,
        buyerPostcode: state.form.postno,
        buyerTel: state.account.mobile,
        merchantUid: merchantUid,
        name: state.project.projectName,
        amount: computedFinalAmount.value,
      };

      if (creditCaredRef.value.state.form.cardNumber) { // 직접 입력한 카드 정보로 결제
        url = `/api/reward/project/${state.project.projectSeq}/payments/card/onetime`;
        args2.birth = creditCaredRef.value.state.form.birth;
        args2.cardNumber = creditCaredRef.value.state.form.cardNumber;
        args2.cardNumbers = creditCaredRef.value.state.form.cardNumbers;
        args2.cardPassword = creditCaredRef.value.state.form.cardPassword;
        args2.expireMonth = creditCaredRef.value.state.form.expireMonth;
        args2.expireYear = creditCaredRef.value.state.form.expireYear;
      } else if (state.project.fundingType === "A") {
        url = `/api/reward/project/${state.project.projectSeq}/payments/schedule/card`;
      } else { // 저장된 카드 정보로 결제
        url = `/api/reward/project/${state.project.projectSeq}/payments/card/againtime`;
      }

      http.post(url, args2).then(({data}) => {
        if (data.body.investorSeq) {
          track.post({
            name: "rewardProjectPay",
            category: "후원하기",
            topic: "후원 완료",
            title: state.project.projectName,
            memo: "card",
            urlPath: url,
            type: "api",
            httpMethod: "POST",
          });

          return router.push({path: `/reward/${state.project.projectSeq}/order/${data.body.investorSeq}`});
        }

        store.commit("setSwingMessage", definitions.messages.errorCommon);
      }).catch();
    };

    const requestIamport = () => {
      store.dispatch("appendScript", {
        src: "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js",
        onFirstLoad() {
          window.IMP.init(definitions.imp.initId);
        },
        onEveryLoad() {
          const args = {
            pg: "nice",
            merchant_uid: merchantUid,
            pay_method: "",
            name: state.project.projectName,
            amount: computedFinalAmount.value,
            buyer_email: state.account.email,
            buyer_name: state.account.memberName, // 가변적일 수 있음
            buyer_tel: state.form.investorHp.replaceAll("-", ""), // 가변적일 수 있음
            buyer_addr: "",
            buyer_postcode: "",
            custom_data: {
              projectSeq: projectSeq,
              paymethod: state.form.optPayment,
              fundingType: state.project.fundingType,
              noRewardFlag: state.rewardItems[0].rewardSeq ? "N" : "Y",
              memberSeq: state.account.memberSeq,
              memberId: state.account.memberId,
              buyerEmail: state.account.email
            },
            notice_url: definitions.imp.noticeUrl,
            naverProductCode: ""
          };

          switch (state.form.optPayment) {
            case "real":
              args.pay_method = "trans";
              break;

            case "virtual": {
              const nowDate = new Date();
              nowDate.setDate(nowDate.getDate() + 3);
              args.pay_method = "vbank";
              args.vbank_due = lib.getDateFormat(nowDate, "yyyyMMdd");
              break;
            }

            case "naverpay": {
              args.pg = state.form.optPayment;
              args.pay_method = state.form.optPayment;
              args.naverPopupMode = false;

              if (state.project.fundingType === "A") {
                args.custom_data.paymethod = "naverpay";
                args.customer_uid = merchantUid;
                args.merchant_uid = merchantUid + "_4register";
                args.naverProductCode = merchantUid;
                args.notice_url = null; // 네이버페이 예약결제인 경우 성공완료는 아임포트 관리자에서 지정한 주소로 Noti하도록 한다.
              } else {
                const expDate = new Date();
                expDate.setDate(expDate.getDate() + 90);
                args.naverUseCfm = lib.getDateFormat(expDate, "yyyyMMdd"); // KIA 이용완료일자 = 결제일 + 90일

                if (state.rewardItems[0].rewardSeq) {
                  let title = state.rewardItems[0].title;

                  if (title && title.length > 128) {
                    title = title.substring(0, 128);
                  }

                  args.naverProducts = [{
                    categoryType: "ETC",
                    categoryId: "ETC",
                    uid: state.rewardItems[0].rewardSeq,
                    name: title,
                    count: state.form.rewardItems[0].selectCount,
                  }];
                } else {
                  args.naverProducts = [{
                    categoryType: "PRODUCT",
                    categoryId: "SUPPORT",
                    uid: "NOREWARD_" + projectSeq,
                    name: "리워드없이참여",
                    count: "1"
                  }];
                }
              }
            }
          }

          const callbackPath = `/callback/pay?projectSeq=${projectSeq}&payMethod=${state.form.optPayment}&fundingType=${state.project.fundingType}`;

          if (env.device === "mobile" || !args.naverPopupMode) {
            args.m_redirect_url = location.origin + callbackPath;
          }

          // 아임포트 결제 호출(PC는 모달, Mobile은 리디렉션)
          window.IMP.request_pay(args, (res) => {
            if (res.success) {
              router.push({path: `${callbackPath}&imp_uid=${res.imp_uid}&merchant_uid=${res.merchant_uid}`});
            } else {
              store.commit("setSwingMessage", res.error_msg || definitions.messages.errorCommon);
            }
          });
        }
      });
    };

    const postOmcPay = () => {
      const args = {
        merchantUid: merchantUid,
        paymethod: state.form.optPayment,
        memberSeq: store.state.account.memberSeq,
        memberId: store.state.account.memberId,
        projectName: state.project.projectName,
      };

      http.post(`/api/reward/project/${projectSeq}/payments/omcpay`, args).then(({data}) => {
        return router.push({path: `/reward/${projectSeq}/order/${data.body.investorSeq}`});
      }).catch(httpError());
    };

    const submit = async () => {
      if (!await validate()) {
        return;
      } else if (!await postAddress()) {
        return;
      } else if (!await putNftWalletAddress()) {
        return;
      }

      store.commit("setLoading", true);
      http.get("/api/reward/project/uid").then((res) => {
        store.commit("setLoading", false);
        merchantUid = res.data.body;

        putInvestTmp(() => {
          if (state.project.paymethods.map(m => m.name).includes(state.form.optPayment)) {
            state.form.optPayment === "card" ? postCardOrder() : requestIamport();
          } else {
            postOmcPay();
          }
        });
      });
    };

    const getExtraCount = (item) => {
      return item.extraCount <= 0 ? "0" : item.extraCount;
    };

    const niceAuth = () => {
      if (state.niceAuth.passed) {
        return window.alert("이미 인증을 완료하셨습니다.");
      }

      if (lib.isInstagramInAppBrowser() || lib.isDaangnInAppBrowser() || lib.isOmcApp()) {
        const href = window.location.host + window.location.pathname + window.location.search;
        const url = lib.isAppleMobile() ? `x-safari-${window.location.href}` : `intent://${href}#Intent;scheme=http;package=com.android.chrome;S.browser_fallback_url=${window.location.host};end`;

        return window.confirm("인증을 위해 기본 인터넷 어플로 이동하여 다시 시도해주십시오") && (window.location.href = url);
      }

      let popUrl = "about:blank";
      const popupName = "popupChk";
      const nicePopupWindow = window.open(popUrl, popupName, "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");

      if (nicePopupWindow == null) {
        return window.alert("팝업 차단기능 혹은 팝업 차단 프로그램이 동작 중입니다. 팝업 차단을 해제하신 후 다시 시도해주세요.");
      }

      const args = {
        EncodeData: state.niceAuth.encodeData,
        m: "checkplusSerivce",
        param_r1: "memberAuth", // 매개변수1(인증 구분 값)
      };

      const form = document.createElement("form");
      form.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
      form.method = "post";
      form.target = popupName;

      for (let i in args) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = i;
        input.value = args[i];
        form.append(input);
      }

      document.body.append(form);

      window.niceCertCallback = (res) => {
        res?.code === "0" && (state.niceAuth.passed = true);
      };

      form.submit();
      form.remove();
    };

    const skipNiceAuthAndAddressVerify = (e) => {
      const isChecked = e.target?.checked;
      isChecked && window.alert("해외 거주자의 경우, 리워드 배송 제한이 있을 수 있습니다. 이에 대한 내용은 프로젝트 진행자에게 문의해주시기 바랍니다.");

      state.account.niceAuthFlag !== "Y" && (state.niceAuth.passed = isChecked);
      state.overseas = isChecked;
    };

    watch(() => state.form.addAmt, (next) => {
      if (window.Number(next) < 0) {
        state.form.addAmt = 0;
      }
    });

// watch(() => state.form.rewardItems[0].selectCount, (next) => {
//   if (window.Number(next) < 1) {
//     state.form.rewardItems[0].selectCount = 1;
//   }
// });

    watch(() => computedFinalAmount.value, (next, prev) => {
      if (next === 0) {
        if (state.project.paymethods.map(m => m.name).includes(state.form.optPayment)) {
          prevPayMethod = state.form.optPayment;
        }

        if (state.form.couponPoint > 0 && state.form.cashPoint > 0) {
          state.form.optPayment = "op_po";
        } else if (state.form.cashPoint > 0) {
          state.form.optPayment = "omcpay";
        } else if (state.form.couponPoint > 0) {
          state.form.optPayment = "point";
        }
      } else if (prev === 0 && prevPayMethod) {
        state.form.optPayment = prevPayMethod;
      }
    });

    return {
      component,
      state,
      creditCaredRef,
      rewardCountMaxValue,
      minAddAmt,
      minAmountWithoutReward,
      calendar,
      computedCalcAmount,
      computedMaxCashPoint,
      computedMaxCouponPoint,
      computedFinalAmount,
      computedAddressRequired,
      loadAccount,
      openShippingAddressModal,
      onShippingAddressSelect,
      onMemoSelectChange,
      onAddressSelectChange,
      selectPay,
      changeCheckPointAll,
      changeCheckCouponPointAll,
      changeRewardOptionItems,
      setChatGptCheerMessage,
      setNftWalletAddress,
      setNumber,
      openNftModal,
      submit,
      getExtraCount,
      niceAuth,
      skipNiceAuthAndAddressVerify,
      defaultSupportComment,
    };
  }
});
</script>

<style lang="scss" scoped>
.reward-order-form {
  padding: $px36 0 $px150 0;

  .form-control {
    height: $formHeight;
  }

  > .container > .row {
    > .input {
      > .wrapper {
        position: relative;

        .back {
          display: inline-block;
          text-decoration: none;
          margin-bottom: $px15;

          .img {
            margin-right: $px7;
            width: $px16;
            height: $px16;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
          }

          &:hover {
            span {
              font-weight: 500;
            }
          }
        }

        > .thumbnail {
          position: absolute;
          top: $px15;
          right: 0;
          width: $px160;
          height: $px96;
          background-color: $colorBackground;
          border: $px1 solid $colorBorder;
          border-radius: $px4;
        }

        header {
          padding-right: $px180;
          padding-bottom: $px35;
          margin-bottom: $px35;
          border-bottom: $px1 solid $colorBorder;

          .title {
            padding-bottom: $px8;

            > a {
              display: inline-block;
              text-decoration: none;
              font-size: $px20;
              font-weight: 500;
              word-break: keep-all;
            }
          }

          .builder > a {
            text-decoration: none;
          }
        }

        .parts {
          .part {
            padding-bottom: $px25;
            margin-bottom: $px25;
            position: relative;

            .beside {
              display: inline-block;
              margin-left: $px11;
              vertical-align: top;
              margin-top: $px-2;

              &.recommend {
                .btn {
                  background: $colorBackground;
                  border: 1px solid #eee;
                }
              }
            }

            .side {
              position: absolute;
              top: $px5;
              right: 0;
              font-weight: normal;

              .form-check-input {
                margin-left: $px-18;
              }
            }

            .subject {
              margin-bottom: $px13;
              font-weight: 500;
              font-size: $px18;
              display: inline-block;

              label {
                margin: 0;
              }

              .desc {
                font-weight: normal;
                margin-left: $px7;
                color: #767676;
                font-size: $px14;
              }
            }

            .content {
              .amount {
                .price {
                  float: left;

                  span, b {
                    display: inline-block;
                  }

                  span {
                    color: #666;
                  }

                  b {
                    margin-left: $px6;
                  }
                }

                .count {
                  float: right;
                  text-align: right;
                  margin-top: $px-6;
                  position: relative;
                  display: flex;
                  align-items: center;


                  label {
                    vertical-align: middle;
                    margin: 0 $px10 0 0;
                  }


                  .number-set {
                    border: $px1 solid $colorBorder;
                    border-radius: $px4;
                    overflow: hidden;

                    input[type=number] {
                      display: inline-block;
                      padding-right: $px7;
                      width: $px70;
                      height: $px35;
                      vertical-align: middle;
                      position: relative;
                      background: none;
                      border: solid $colorBorder;
                      border-width: 0 $px1;
                      border-radius: 0;
                      text-align: center;

                      &::-webkit-inner-spin-button,
                      &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                      }

                      &.border-focus-none:focus {
                        border-color: $colorBorder;
                      }
                    }

                    .btn {
                      border: 0;
                      border-radius: 0;
                      width: $px32;

                      &:hover {
                        border: 0;
                      }
                    }
                  }
                }
              }

              .title {
                margin-top: $px13;
                background: $colorBackground;
                padding: $px11 $px15;
                border: $px1 solid $colorBorder;
                border-radius: $px4;

                span {
                  display: inline-block;
                }
              }

              .option {
                margin-top: $px10;
              }

              .select {
                margin-top: $px20;
                position: relative;

                .form-control {
                  padding: $px12;
                  resize: none;
                }
              }

              .reservation {
                padding-top: $px25;
              }
            }

            &.overseas {
              label {
                line-height: 1;
              }
            }

            &.amount {
              .subject .desc {
                margin-left: 0;
                margin-top: $px2;
              }

              .side {
                right: $px20;

                > span {
                  position: absolute;
                  top: $px8;
                  right: $px-20;
                }
              }
            }

            &.mobile {
              .subject .desc {
                margin-left: 0;
                margin-top: $px2;
              }
            }

            &.cheer {
              .content {
                padding-top: $px5;

                .input-group {
                  position: relative;

                  .input-group-prepend {
                    padding-right: $px15;

                    .img.profile {
                      width: $px40;
                      height: $px40;
                    }
                  }
                }

                textarea {
                  min-height: $px76;
                  border-radius: $px4;
                }
              }
            }

            &.paid {
              span {
                vertical-align: middle;

                &.img {
                  width: $px16;
                  height: $px16;
                  margin-right: $px3;
                }
              }
            }

            &.receive {
              > .subject {
                margin-bottom: 0;
              }

              .side {
                top: $px-1;

                .btn {
                  float: right;
                  margin-left: $px10;
                }

                > div {
                  padding-top: $px5;
                  float: right;

                  label {
                    vertical-align: middle;
                  }
                }
              }

              .content {
                .form-group {
                  margin-bottom: 0;
                  padding-top: $px15;
                  padding-bottom: $px15;
                  position: relative;
                }

                .default {
                  position: absolute;
                  top: $px4;
                  right: 0;
                }
              }
            }

            &.point {
              .times {
                position: absolute;
                top: $px8;
                right: $px94;
                cursor: pointer;
              }

              input[type=text]:focus + .times {
                z-index: 3;
              }
            }

            &.payment {
              .content ul {
                margin-bottom: $px-10;

                li {
                  display: inline-block;
                  margin-right: $px10;
                  margin-bottom: $px10;

                  button {
                    border: $px1 solid $colorBorder;
                    width: $px110;
                    text-align: center;
                    padding: $px25 0 $px20 0;
                    outline: $px1 solid transparent;
                    position: relative;

                    > span {
                      display: inline-block;
                      width: $px33;
                      height: $px33;
                      position: relative;

                      > .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      }
                    }

                    div {
                      margin-top: $px3;
                    }

                    &:focus {
                      box-shadow: none;
                    }
                  }

                  &.active button {
                    border-color: $colorPoint;
                    color: $colorPoint;
                    font-weight: 500;
                    outline-color: $colorPoint;

                    .img.off {
                      z-index: -1;
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

              .pay {
                padding-top: $px15;
                max-width: $px470;
              }
            }
          }
        }
      }
    }

    > .pay {
      padding-left: $px66;

      > .wrapper {
        position: sticky;
        top: $px178;
        max-width: $px470;
        margin: 0 auto;

        > .outer {
          border: $px1 solid $colorBorder;
          border-radius: $px4;

          .inner {
            padding: $px25 $px25 $px10 $px25;

            .subject {
              margin-bottom: $px15;
              font-weight: 500;
              font-size: $px20;
            }

            .details {
              border-bottom: $px1 solid $colorBorder;
              padding-bottom: $px25;
              margin-bottom: $px25;

              table {
                th, td {
                  border: 0;
                  padding: $px5 0;
                }

                th {
                  font-weight: normal;
                }

                td {
                  text-align: right;
                }
              }
            }
          }

          .guide {
            border-top: $px1 solid $colorBorder;
            padding: $px25 $px20;
            background: $colorBackground;
            word-break: keep-all;

            .form-check:not(:first-child) {
              margin-top: $px7;
            }
          }
        }

        .action {
          padding-top: $px25;

          button {
            padding: $px26 $px15;
          }
        }
      }
    }
  }

  &.skeleton::v-deep {
    .form-control, button {
      @include skeleton;
    }

    .check {
      padding-left: 0;

      input {
        display: none
      }

      > div, > label {
        @include skeleton;
      }
    }

    > .container > .row {
      input[type=checkbox] {
        display: none;

        & + label {
          @include skeleton;
        }
      }

      > .input {
        .wrapper {
          .back {
            @include skeleton;

            .img {
              visibility: hidden;
            }
          }

          > .thumbnail {
            @include skeleton;
          }
        }

        header {
          .img, .title > a, .builder > a {
            @include skeleton;
          }
        }

        .parts .part {
          .subject {
            span, label, b {
              @include skeleton;
            }
          }

          label, label span {
            @include skeleton;
          }

          .content {
            .amount {
              .price {
                min-height: $px30;
                min-width: $px120;
                @include skeleton;

                span, b {
                  display: none;
                }
              }

              .count {
                label, span, input {
                  @include skeleton;
                }
              }
            }

            .title {
              @include skeleton;
            }

            .select label {
              @include skeleton;
            }

            .option {
              label {
                @include skeleton;
              }
            }
          }

          &.amount .side span {
            @include skeleton;
          }

          &.cheer {
            .subject .form-check {
              padding-left: 0;

              input {
                display: none
              }

              > label {
                @include skeleton;
                min-width: $px49;
              }
            }

            .beside {
              .btn {
                @include skeleton;

                span {
                  visibility: hidden;
                }
              }

              .badge {
                @include skeleton;
              }
            }

            .profile.img {
              @include skeleton;
            }
          }

          &.point .content .input-group .input-group-append {
            display: none;

            .times {
              display: none;
            }
          }

          &.payment .content ul li .img {
            visibility: hidden;
          }
        }
      }

      > .pay {
        > .wrapper {
          border-color: #eee;

          .inner {
            .subject {
              > span {
                @include skeleton;

                span, b {
                  visibility: hidden;
                }
              }
            }

            .details table {
              th, td span {
                @include skeleton;
              }
            }
          }

          .guide {
            p {
              @include skeleton;
            }

            .read a {
              @include skeleton;
            }
          }
        }

        button {
          .img {
            display: none;
          }
        }
      }
    }
  }

  @media(min-height: 768px) {
    > .container > .row > .input > .wrapper {
      .parts .part:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  @media(max-width: 1199px) {
    > .container > .row > .pay {
      padding-left: $px30;
    }
  }

  @media(max-width: 991px) {
    padding: $px15 0 $px50 0;

    > .container > .row {
      > .input {
        > .wrapper {
          .thumbnail {
            top: $px42;
            width: $px120;
            height: $px72;
          }

          header {
            padding-right: $px130;

            .title {
              font-size: $px16;
              white-space: normal;
            }
          }

          .parts .part {
            padding-bottom: $px15;
            margin-bottom: $px15;

            .subject {
              font-size: $px15;

              .desc {
                display: block;
                font-size: $px12;
                margin-left: 0;
                margin-top: $px5;
              }
            }

            .content {
              .amount .count .arrows {
                display: none;
              }
            }

            > .side {
              top: $px4;
              right: 0;
            }

            &.amount {
              > .side {
                padding-right: $px25;
                top: 0;
                right: 0;
                position: relative;

                > span {
                  right: 0;
                }
              }
            }

            &.mobile {
              > .side {
                position: static;
              }
            }

            &.cheer {
            }

            &.payment .content ul {
              padding-top: $px1;
              white-space: nowrap;
              overflow: auto;
              margin-right: $px-15;

              > li:last-child {
                margin-right: $px15;
              }
            }

            &.payment {
              .content ul {
                padding-left: $px1;

                li button {
                  width: $px90;
                  padding: $px20 0 $px15 0;

                  > span {
                    width: $px25;
                    height: $px25;
                  }
                }
              }
            }

            &.point, &.coupon, {
              .subject .desc {
                display: inline;
                margin-left: $px7;
              }
            }

            &.coupon {
              .select {
                margin-top: $px30;
              }
            }

            &.receive {
              .side {
                top: $px-4;
              }
            }
          }
        }
      }

      > .pay {
        padding-top: $px25;
        padding-left: $px15;

        > .wrapper {
          position: static;
          max-width: 100%;

          > .outer .inner .subject {
            font-size: $px18;
          }
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .container > .row > .input > .wrapper header .title > span {
      font-size: $px18;
    }
  }

  @media(max-height: 767px) {
    > .container > .row > .pay > .wrapper {
      position: static;
    }
  }

}
</style>