<template>
  <div class="detail-cheers" :class="{skeleton:!state.loaded}" ref="wrapRef">
    <template v-if="state.cheer && state.cheer.rewardCheerList && state.cheer.rewardCheerList.length">
      <ul class="tight">
        <li v-for="(c, idx) in state.cheer.rewardCheerList" :key="idx" :data-depth="c.depth">
          <div class="wrapper">
            <div class="roof clearfix">
              <div class="profile">
                <template v-if="c.hideInvestorNameYn === 'Y' || (c.memberAuth && c.memberAuth.includes('ADMIN'))">
                  <MemberPicture :hide="c.hideInvestorNameYn === 'Y'" :memberSeq="c.memberSeq" size="35"/>
                  <span class="name font-sm">{{ c.hideInvestorNameYn === "Y" ? "키다리 후원자" : "오마이컴퍼니" }}</span>
                </template>
                <router-link :to="`/channel/${c.memberSeq}?tabs=projects`" v-else>
                  <MemberPicture :memberSeq="c.memberSeq" size="35"/>
                  <span class="name font-sm">{{ c.memberName }}</span>
                </router-link>
                <div class="date font-xs">{{ c.depth === "0" ? c.regDate : c.createDate }}</div>
              </div>
            </div>
            <div class="body font-sm">
              <span>{{ c.content && c.content.trim() ? c.content : "(내용 없음)" }}</span>
              <!--              <div class="actions" v-if="c.memberSeq && c.memberSeq === $store.state.account.memberSeq && c.depth === '0' && c.restoreFlag !== 'N'" @click="editCommentModel(c)">-->
              <!--                <i class="fa fa-pencil font-xs"></i>-->
              <!--              </div>-->
            </div>
            <div class="amount color-point border-point" v-if="c.totalAmt">
              <div>
                <span>{{ $lib.getNumberFormat(c.totalAmt) }}</span>
                <span>{{ computedCurrencyUnit }}</span>
              </div>
              <div class="text">후원 완료</div>
            </div>
          </div>
        </li>
      </ul>
      <div class="page">
        <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
      </div>
    </template>
    <div class="part" v-else>
      <NoData/>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import MemberPicture from "@/components/MemberPicture.vue";
import Pagination from "@/components/Pagination.vue";
import NoData from "@/components/NoData.vue";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageProjectDetailCheers";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, Pagination, NoData},
  props: {
    projectSeq: Number,
    projectType: String,
    investorNameOpenYn: String,
    investorNameAltText: String,
    currencyUnit: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 10; i += 1) {
        state.cheer.rewardCheerList.push({
          depth: "0",
          investorSeq: 0,
          memberAuth: "",
          memberName: "Wait",
          memberSeq: "Wait",
          regDate: "000-00-008 00:00",
          supportComment: "Wait a moment",
          totalAmt: 1000
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      cheer: {
        rewardCheerList: [],
      },
      pagination: null,
    });

    const wrapRef = ref();

    const computedCurrencyUnit = computed(() => {
      return props.currencyUnit || "원";
    });

    const load = (num) => {
      state.loaded = false;
      http.get(`/api/reward/projects/${props.projectSeq}/cheer`, {
        pageIndex: num ? num : 1,
        pageUnit: 10,
        pageSize: 5,
      }).then(({data}) => {
        state.loaded = true;
        state.cheer = data.body;
        state.pagination = data.body.paginationInfo;

        if (props.investorNameOpenYn === "N" && state.cheer?.rewardCheerList?.length) {
          for (let i in state.cheer.rewardCheerList) {
            state.cheer.rewardCheerList[i].memberName = props.investorNameAltText || (state.cheer.rewardCheerList[i].memberName.substr(0, 1) + "**");
          }
        }

        // # 976 후원자 이름 변경
        // # 1121 후원자 이름 변경
        if ([15262, 15795].includes(props.projectSeq)) {
          for (let i in state.cheer.rewardCheerList) {
            state.cheer.rewardCheerList[i].memberSeq = null;
            state.cheer.rewardCheerList[i].memberName = state.cheer.rewardCheerList[i].receiveName;
          }
        }
      });

      num && nextTick(() => {
        const rect = wrapRef.value.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
          top: rect.top + scrollTop - 60 - lib.getHeaderHeight(),
          left: 0,
          behavior: "smooth"
        });
      });
    };

    // 후원 시 응원메세지를 따로 작성하지 않은 경우 tb_omc_project_investor_comment 에 값이 없어 수정 안 됨.
    // @Todo 후원 시 무조건 응원 메세지 DB insert
    const putComment = ({investorSeq, content}) => {
      http.put("/api/reward/projects/support-comment", {investorSeq, content}).then(() => {
        store.commit("setSwingMessage", "응원 댓글을 수정하였습니다.");
        load();
      }).catch(httpError());
    };

    const editCommentModel = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          investorSeq: comment.investorSeq,
          content: comment.content
        },
        callback: `${component.name}.putComment`,
      });
    };

    return {component, state, wrapRef, computedCurrencyUnit, putComment, editCommentModel, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-cheers {
  > ul {
    > li {
      margin-top: $px13;
      position: relative;

      > div.wrapper {
        border-radius: $px4;
        border: $px1 solid #eee;
        background: #fff;
        position: relative;

        .roof {
          padding: $px30 $px30 $px25 $px30;

          .profile {
            float: left;
            position: relative;
            padding-top: $px1;
            padding-left: $px45;

            .img {
              position: absolute;
              top: $px4;
              left: 0;
              border: $px1 solid #eee;
              border-radius: 50%;
            }

            .name {
              font-weight: 500;
              margin-bottom: $px1;
            }

            .date {
              color: #767676;
            }
          }
        }

        .body {
          padding: 0 $px30 $px30 $px30;

          .actions {
            cursor: pointer;
            display: inline-block;
            padding: $px10;
            margin: $px-10 $px-10 $px-10 0;
          }
        }

        .amount {
          position: absolute;
          top: $px30;
          right: $px30;
          border: $px1 solid $colorSecondary;
          text-align: center;
          padding: $px23 $px10;
          min-width: $px91;
          border-radius: $px4;
          height: $px91;

          span {
            font-size: $px14;
          }

          .text {
            margin-top: $px2;
            font-size: $px12;
          }
        }
      }

      &[data-depth="0"] {
        > div.wrapper {
          padding-right: $px135;
        }
      }

      &[data-depth="1"] {
        padding-left: $px50;

        &:before {
          content: " ";
          background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
          background-size: 100% 100%;
          width: $px30;
          height: $px30;
          position: absolute;
          top: $px3;
          left: $px11;
        }
      }
    }
  }

  .page {
    margin: $px25 0;
  }

  &.skeleton {
    ul > li {
      .roof {
        .profile {
          .img, .name, .date {
            @include skeleton;
          }
        }
      }

      .body > span {
        @include skeleton;
      }

      .amount {
        @include skeleton;
      }
    }
  }

  @media (max-width: 991px) {
    > ul > li {
      padding-right: 0;
      margin-top: $px10;

      div.wrapper {
        .roof {
          padding: $px20 $px20 $px15 $px20;
        }

        .body {
          padding: 0 0 $px20 $px20;
        }

        .amount {
          top: $px15;
          right: $px15;
        }
      }

      &[data-depth="1"] {
        div.wrapper .body {
          padding-right: $px20;
        }
      }
    }
  }
}
</style>