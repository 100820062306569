<template>
  <div class="policy">
    <div class="container">
      <h1 class="page-top">
        <div class="title">약관 및 정책</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <h2>후원형 개인정보처리방침</h2>
            <div class="section">
              <p>
                주식회사 오마이컴퍼니(이하 "당사"라고 합니다)는 고객의
                개인정보보호를 매우 중요시하며, 개인정보보호법,
                정보통신망이용촉진등에 관한 법률 등 관련법령, 규정을 준수하고
                있습니다.
              </p>
              <p>
                당사는 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가
                어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
                어떠한 조치가 취해지고 있는지 알려드립니다.
              </p>
              <p>
                당사는 개인정보처리방침을 홈페이지 첫 화면에 공개함으로써
                귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
                당사의 개인정보처리방침은 정부의 법률 및 지침 변경이나 당사 내부
                방침변경 등으로 수시로 변경될 수 있으며, 개인정보처리방침의
                지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 개인정보
                보호정책을 개정하는 경우 당사는 그 변경사항을 홈페이지에
                게시하여 이용자들이 개정된 사항을 쉽게 알아볼 수 있도록 하고
                있습니다. 귀하께서는 사이트 방문 시 수시로 확인하시기 바랍니다.
              </p>
            </div>
            <div lcass="section">
              <h3>제 1 조 개인정보보호방침의 순서</h3>
              <ul class="tight">
                <li>① 개인정보의 수집목적 및 이용목적</li>
                <li>② 개인정보의 수집범위 및 수집방법</li>
                <li>③ 개인정보의 이용 및 제공</li>
                <li>④ 개인정보처리의 위탁</li>
                <li>⑤ 개인정보 처리에 대한 동의 및 방법</li>
                <li>⑥ 개인정보의 수집</li>
                <li>⑦ 개인정보의 열람/정정</li>
                <li>⑧ 동의철회(회원탈퇴)</li>
                <li>⑨ 개인정보의 보유 및 이용기간</li>
                <li>⑩ 개인정보의 파기절차 및 방법</li>
                <li>⑪ 법정대리인의 권리</li>
                <li>
                  ⑫ 개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항
                </li>
                <li>⑬ 개인정보 안정성 확보조치</li>
                <li>⑭ 개인정보침해 관련 상담 및 신고</li>
                <li>⑮ 개인정보 처리방침의 변경에 따른 공지의무</li>
                <li>⑯ 개인정보관리책임자</li>
              </ul>
            </div>
            <div class="section">
              <h3>제 2 조 개인정보의 수집목적 및 이용목적</h3>
              <ul class="tight">
                <li>
                  ① 당사는 사이트(https://www.ohmycompany.com) 통합 서비스
                  사용에 있어 최적의 서비스를 제공하기 위한 목적으로 회원님의
                  개인정보를 수집하고 있습니다.
                </li>
                <li>
                  <p>
                    ② 관련 법령 또는 규정이 허용하는 범위 내에서 당사는 본
                    개인정보 처리방침 전문에 기재된 목적을 위해 개인정보를 수집
                    · 이용합니다. 수집되는 개인정보 항목에 따른 구체적인
                    수집목적 및 이용목적은 다음과 같습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 성명, 아이디, 비밀번호: 회원가입 및 이용 ID발급, 회원제
                      서비스 이용에 따른 본인 확인 절차에 이용, 개인식별,
                      불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인
                      등
                    </li>
                    <li>
                      2. 이메일 주소, 전화번호: 불만처리 등을 위한 원활한
                      의사소통과 새로운 상품, 서비스 및 신상품이나 이벤트 정보
                      등의 안내, 뉴스레터 등 고지사항 전달
                    </li>
                    <li>
                      3. 주소 : 상품의 배송지의 확보 및 이벤트 진행 시 배송지
                      확보
                    </li>
                    <li>
                      4. 사이트 이용 현황 : 당사 사이트 접속빈도 등에 대한
                      측정을 통해 이용 통계를 수집하고, 이를 서비스 정책(서비스
                      개편 및 확대)에 반영
                    </li>
                    <li>
                      5. 기타 선택 항목 : 개인맞춤 서비스를 제공하기 위한 자료
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 3 조 개인정보의 수집범위 및 수집방법</h3>
              <ul class="tight">
                <li>
                  <p>① 수집범위</p>
                  <ul class="list_depth1">
                    <li>
                      1. 당사는 적법하고 공정한 절차와 이용자의 동의를 받은
                      개인정보를 수집합니다. 이를 위해 개인정보 처리방침 또는
                      이용약관의 내용에 대하여, 동의 버튼을 클릭할 수 있는
                      절차를 마련하고 동의 버튼을 클릭하면 개인정보 수집에 대해
                      동의한 것으로 봅니다.
                    </li>
                    <li>
                      2. 당사가 수집하는 개인정보 항목 : 아이디, 이메일,
                      비밀번호, 생년월일, 전화번호, 주소
                    </li>
                    <li>
                      3. 홈페이지 회원 가입 시 주민등록번호를 요구하지 않습니다.
                    </li>
                    <li>
                      4. 당사는 회원의 기본적인 인권 침해 우려가 있는 민감한
                      개인정보는 수집하지 않습니다. 다만 이용자의 적법한 동의가
                      있거나 법령의 규정에 의한 경우는 예외로 합니다.
                    </li>
                  </ul>
                </li>
                <li>
                  ② 수집방법 : 홈페이지를 통한 회원 가입 및 변경, 문의 메일,
                  전화 상담 등
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 4 조 개인정보의 이용 및 제공</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① 당사에서는 수집한 개인정보를 다음의 목적을 위해 이용할 수
                    있습니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 홈페이지 회원관리 : 회원제 서비스 이용 등에 따른
                      본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가
                      사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 고지사항
                      전달, 상담처리 등
                    </li>
                    <li>2. 이메일 서비스 제공</li>
                    <li>3. 설문조사와 이벤트를 위한 이용</li>
                    <li>
                      4. 컨텐츠 및 회원 맞춤형 서비스 제공, 서비스 구매 및
                      요금결제, 금융거래 본인인증 및 금융 서비스, 상품 주문에
                      따른 배송 서비스
                    </li>
                    <li>
                      5. 신규서비스(콘텐츠) 개발 및 특화, 이벤트 등 광고성 정보
                      전달, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속
                      빈도 파악, 회원의 서비스 이용에 대한 통계
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    ② 회원이 제공한 개인정보는 당해 회원의 동의 없이는 당사가
                    이미 고지 또는 명시한 목적범위 이외로 이용하거나 제3자에게
                    제공할 수 없습니다. 다만, 아래의 경우에는 예외로 합니다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 수사를 목적으로 법령에 정해진 절차와 방법에 따라
                      수사기관 등의 요구가 있는 경우
                    </li>
                    <li>
                      2. 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용
                      및 보호에 관한 법률, 전기통신기본법, 전기통신사업법,
                      지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법령에
                      특별한 규정이 있는 경우
                    </li>
                    <li>
                      3. 법령의 규정에 근거하여 통계목적, 학술연구, 시장조사
                      등에 특정 개인을 식별할 수 없는 형태로 개인정보를 제공하는
                      경우
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    ③ 회원의 동의를 받아야 하는 경우에도 당사는 다음 사항을 미리
                    명시하거나 고지해야 하며 회원은 이에 대해 거절할 권리를
                    가지며, 또한 언제든지 동의를 철회할 수 있습니다.
                  </p>
                  <table class="omc-table">
                    <colgroup>
                      <col style="width: 20%"/>
                      <col style="width: 80%"/>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>제공받는자</th>
                      <td>프로젝트 진행자</td>
                    </tr>
                    <tr>
                      <th>목적</th>
                      <td>
                        리워드 펀딩 정보 확인 및 발송, 리워드 발송과 관련된
                        공지 및 민원처리
                      </td>
                    </tr>
                    <tr>
                      <th>항목</th>
                      <td>
                        후원자 정보(이름, 성별, 이메일, 휴대폰 번호)<br/>
                        수취인 정보(이름, 휴대폰 번호, 주소, 배송메모)
                      </td>
                    </tr>
                    <tr>
                      <th>보유기간</th>
                      <td>3 년(소비자기본 관련법령에 따라 적용)</td>
                    </tr>
                    </tbody>
                  </table>
                  <ul class="list_depth1">
                    <li>
                      1. 개인정보보호 책임자의 신원(소속,성명 및 전화번호)
                    </li>
                    <li>2. 수집하고자 하는 개인정보 항목</li>
                    <li>3. 수집하고자 하는 개인정보의 보유기간 및 이용기간</li>
                    <li>4. 제3자에 대한 정보제공 관련사항</li>
                    <li>
                      5. 회원의 동의철회권과 자신의 정보에 대한 열람·오류정정
                      요구권 및 그 행사방법
                    </li>
                    <li>
                      6. 기타 정보통신망이용촉진등에 관한 법률 등 관계법령이
                      규정한 사항
                    </li>
                  </ul>
                </li>
                <li>
                  ④ 이메일 등 개인정보가 부정확하여 서비스의 품질에 지장을
                  초래하는 경우에는 홈페이지 운영자가 해당정보를 삭제하거나
                  수정할 수 있습니다.
                </li>
                <li>
                  ⑤ 회원이 온라인 상에서 자발적으로 제공하는 개인정보는 제3자가
                  수집하여 사용할 수 있으며 이에 대한 책임은 회원에게 있습니다.
                </li>
                <li>
                  ⑥ 또한 업무수행을 위해 개인정보를 확인하는 것은 접근 권한이
                  있는 업무담당자에 한정되어 있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 5 조 개인정보처리의 위탁</h3>
              <p>
                당사는 서비스 향상 및 원활한 계약사항의 이행 등을 위하여
                개인정보 처리업무를 외부 전문업체에 일부 위탁하고 있습니다.
              </p>
              <p>
                당사가 개인정보처리를 위탁하고 있는 수탁사 제공대상자 및
                위탁업무내용, 제공정보는 다음과 같습니다.
              </p>
              <table class="omc-table">
                <colgroup>
                  <col style="width: 30%"/>
                  <col style="width: 30%"/>
                  <col style="width: 40%"/>
                </colgroup>
                <thead>
                <tr>
                  <th>제공 대상자</th>
                  <th>위탁업무내용</th>
                  <th>제공정보</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    나이스정보통신(주)<br/>
                    네이버파이낸셜(주)<br/>
                    아임포트
                  </td>
                  <td>결제처리</td>
                  <td>
                    고객명/휴대폰번호/생년월일, 신용(체크) 카드번호/유효기간,
                    비밀번호(앞 2자리) 등 결제정보
                  </td>
                </tr>
                <tr>
                  <td>
                    ㈜슬로워크<br/>
                    ㈜엠투넷<br/>
                    ㈜컴홈<br/>
                    카페24㈜<br/>
                    facebook<br/>
                    (주)케이티엠하우스<br/>
                  </td>
                  <td>
                    마케팅<br/>
                    이메일, SMS,알림톡 발송
                  </td>
                  <td>고객명/이메일/전화번호</td>
                </tr>
                </tbody>
              </table>
              <p>
                당사는 귀하의 개인정보를 제휴업체에 제공하거나 공유하는 경우에
                사전에 귀하에게 제공받거나 공유 받는 자, 목적, 공유되는
                개인정보항목 등에 대해 개인정보처리방침에 고지한 후 이에 대한
                동의를 구합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 6조 개인정보 처리에 대한 동의 및 방법</h3>
              <p>
                당사는 귀하의 개인정보 수집 및 이용약관에 대한 동의를 받고
                있습니다.
              </p>
              <p>
                귀하의 개인정보 수집과 관련하여 당사의 개인정보보호방침 또는
                이용약관의 내용에 대해 “동의” 버튼 또는 “취소”버튼을 클릭할 수
                있는 절차를 마련하고 있으며, 귀하가 “동의”버튼을 클릭하면 당사의
                개인정보 수집 및 이용약관에 대해 동의한 것으로 봅니다.
              </p>
            </div>
            <div class="section">
              <h3>제 7조 개인정보의 수집</h3>
              <p>
                당사는 회원 가입시 서비스 이용을 위해 필요한 최소한의
                개인정보만을 수집합니다.
              </p>
              <p>
                귀하가 당사의 서비스를 이용하기 위해서는 회원 가입시(아이디,
                비밀번호, 이름, 성별, 전화번호, 이메일 주소)를 필수적으로 입력해야
                합니다. 그러나 선택항목은 이를 입력하지 않더라도 서비스이용에는
                제한이 없습니다.
              </p>
            </div>
            <div class="section">
              <h3>제 8조 개인정보의 열람/정정</h3>
              <p>
                귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나
                정정하실 수 있습니다.
              </p>
              <p>
                개인정보 열람 및 정정을 하고자 할 경우에는 [계정정보]메뉴를
                통하여 직접 열람 또는 정정 가능합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 9 조 동의철회(회원탈퇴)</h3>
              <p>
                회원 및 법정 대리인은 언제든지 등록되어 있는 자신 또는 대리자의
                개인정보를 조회하거나 수정할 수 있으며, 회원탈퇴절차를 통해
                가입해지를 요청할 수도 있습니다.
              </p>
              <p>
                회원 혹은 대리인의 개인정보 조회, 수정을 위해서는 홈페이지의
                '마이페이지'내의 '계정정보’를, 가입해지를 위해서는 ‘계정정보’에
                있는 ‘회원탈퇴’를 이용하시면 됩니다.
              </p>
              <p>
                이 외에도, 당사의 개인정보관리책임자에게 서면, 전화 또는
                omc@ohmycompany.com으로 연락하시면 지체 없이 조치하겠습니다.
              </p>
              <p>
                회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
                완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
              </p>
              <p>
                당사는 회원이 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
                개인정보는 당사가 수집하는 “개인정보의 보유 및 이용기간”에
                명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수
                없도록 처리하고 있습니다.
              </p>
            </div>
            <div class="section">
              <h3>제 10 조 개인정보의 보유 및 이용기간</h3>
              <p>
                당사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
                개인정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여
                보존할 필요가 있는 경우, 당사는 아래와 같이 관계법령에서 정한
                일정한 기간 동안 회원정보를 보관합니다.
              </p>
              <ul class="tight">
                <li>가. 표시 / 광고에 관한 기록 : 6개월</li>
                <li>나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
                <li>다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                <li>라. 신용정보의 수집, 처리 및 이용 등에 관한 기록: 3년</li>
              </ul>
              <p>
                당사는 원칙적으로 개인정보수집에 대한 동의철회가 있거나
                개인정보수집 및 이용목적이 달성된 후에는 해당정보를 지체 없이
                파기합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 11 조 개인정보의 파기절차 및 방법</h3>
              <p>
                귀하는 회원가입 시 개인정보의 수집·이용 및 제공에 대해 동의한
                내용은 언제든지 철회하실 수 있습니다.
              </p>
              <p>
                당사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후에는
                해당 정보를 지체없이 파기하되, 귀하가 회원가입 등을 위해
                입력하신 정보는 내부 방침 및 기타 관련법령에 따라 일정기간 저장
                후 파기될 수 있습니다. 이때 일정기간 저장된 개인정보는 법률에
                의한 경우가 아니고서는 보유 되어지는 이외의 다른 목적으로
                이용되지 않습니다.
              </p>
              <p>
                파기 방법은 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을
                재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
              </p>
            </div>
            <div class="section">
              <h3>제 12 조 법정대리인의 권리</h3>
              <p>
                만14세 미만의 아동으로부터 개인정보를 수집하거나 제3자에게
                아동의 개인정보를 제공하고자 하는 경우에는 그 법정대리인의
                동의를 구해야 합니다.
              </p>
              <p>
                이 경우 당사는 그 아동에게 법정대리인의 동의를 얻기 위하여
                필요한 법정대리인의 성명 등의 정보를 요구할 수 있습니다.
                법정대리인은 상기 아동의 개인정보에 대한 동의를 철회할 수
                있으며, 당해 아동이 제공한 개인정보에 대한 열람 또는 오류의
                정정을 요구할 수 있습니다.
              </p>
              <p>
                당사는 법정대리인의 동의없이 아동에 관한 정보를 제3자에게
                제공하거나 공유하지 않으며, 아동으로부터 수집한 개인정보에
                대하여 법정대리인이 오류의 정정을 요구하는 경우 그 오류를 정정할
                때까지 해당 개인정보의 이용 및 제공을 금지합니다.
              </p>
            </div>
            <div class="section">
              <h3>
                제 13 조 개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한
                사항
              </h3>
              <p>
                당사는 고객의 사용편의를 위하여 '쿠키(cookie)'를 사용합니다.
                귀하는 쿠키에 대한 선택권이 있습니다. 따라서 귀하는
                웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가
                저장될 때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할
                수도 있습니다. 다만, 고객님께서 쿠키설치를 거부하셨을 경우,
                당사의 일부 서비스를 제공받지 못하는 경우가 발생할 수 있음을
                알려드립니다.
              </p>
            </div>
            <div class="section">
              <h3>제 14 조 개인정보 안정성 확보조치</h3>
              <p>
                당사는 귀하의 개인정보가 분실·도난·유출·변조 또는 훼손되지
                아니하도록 내부 관리계획 수립, 접속기록 보관 등 안전성 확보에
                필요한 기술적·관리적 및 물리적 조치를 다음과 같이 하고 있습니다.
              </p>
              <ul class="tight">
                <li>
                  ① 고객의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송
                  데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한
                  데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                </li>
                <li>
                  ② 당사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를
                  안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고
                  있습니다.
                </li>
                <li>
                  ③ 해킹 등에 의해 고객의 개인정보가 유출되는 것을 방지하기
                  위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있으며, 각
                  서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고
                  있습니다.
                </li>
              </ul>
            </div>
            <div class="section">
              <h3>제 15 조 개인정보침해 관련 상담 및 신고</h3>
              <p>
                개인정보침해에 대한 신고·상담이 필요하신 경우에는 정보통신부
                산하 공공기관인 한국정보보호진흥원(KISA)내
                개인정보침해신고센터로 문의하시기 바랍니다.
              </p>
              <p>개인정보침해신고센터</p>
              <p>전화: (국번없이) 118</p>
              <p>홈페이지: https://privacy.kisa.or.kr</p>
            </div>
            <div class="section">
              <h3>제16조 개인정보 처리방침의 변경에 따른 공지의무</h3>
              <p>
                본 개인정보처리방침은 2021년 8월 19일에 개정되었으며 법령·정책
                또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을
                시에는 변경되는 개인정보처리방침을 시행하기 전에 변경 이유 및
                내용 등을 공지하도록 하겠습니다.
              </p>
            </div>
            <div class="section">
              <h3>개인정보관리책임자</h3>
              <p>성명: 한송이</p>
              <p>직위: 공동대표</p>
              <p>E-mail: zenovefa@ohmycompany.com</p>
              <p>전화: 02)388-2556</p>
              <p>Fax: 0505-300-2556</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pagePolicyRewardPrivacy";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Lnb,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.policy";
</style>