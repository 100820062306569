<template>
  <div class="manage-delivery" :class="{skeleton: !state.loaded}">
    <div class="alert">
      <div>리워드 제공 관리 사용방법</div>
      <ul class="tight">
        <li>1. 리워드가 물품 배송형 이라면 배송필요 여부 체크 후 택배사와 송장번호를 입력한 뒤 제공처리 '저장' 버튼을 클릭해주세요.</li>
        <li>2. 리워드가 서비스 제공형이라면 후원자에게 서비스가 제공되었을 경우 배송필요 여부 없이 제공처리 '저장' 버튼 을 클릭해주세요.</li>
        <li>3. 잘못된 정보로 제공처리가 저장되었다면 '취소' 버튼을 눌러 삭제하시고 다시 입력해주세요.</li>
        <li>4. 프로젝트 진행방식이 성공해야 리워드 방식이라면 결제 완료된 후원정보만 리워드 제공관리 메뉴에 노출됩니다.</li>
        <li>5. 성공해야 리워드 방식은 내 프로젝트 페이지에서 누적금액이 목표금액을 달성하였다면 최종결제 완료된 금액이 목표금액을 달성하지 못하였어도 결제 완료하신 후원자에게는 리워드가 제공되어야 합니다.</li>
      </ul>
    </div>
    <div class="content">
      <template v-if="state.investList.length">
        <div class="header">
          <div class="row">
            <div class="col-6 left">
              <select class="form-control border-focus-point" v-model="state.args.deliveryYn" @change="load()" :disabled="!state.loaded">
                <option value="">리워드 제공 여부</option>
                <option value="Y">리워드 제공</option>
                <option value="N">리워드 미제공</option>
              </select>
            </div>
            <div class="col-6 right">
              <a :href="`/${$definitions.apiVersion}/api/maker/project/reward/${$route.params.projectSeq}/delivery/excel-download`" target="_blank" rel="noopener noreferrer" class="btn btn-secondary font-xs"
                 :class="{disabled: !state.loaded}"
                 :disabled="!state.loaded">엑셀 다운로드</a>
            </div>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <select class="form-control border-focus-point" v-model="state.args.searchCondition" :disabled="!state.loaded">
                <option value="memberName">후원자명</option>
                <option value="memberId">아이디</option>
              </select>
            </div>
            <input type="text" class="form-control border-focus-point" v-model="state.args.searchKeyword" @keyup.enter="load()" :disabled="!state.loaded"/>
            <div class="input-group-append pointer">
              <button class="btn btn-secondary font-xs" @click="load()" :disabled="!state.loaded">검색</button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead class="thead-light">
            <tr>
              <th class="num">
                <span>번호</span>
              </th>
              <th class="member">
                <span>후원자(아이디)</span>
              </th>
              <th class="reward">
                <span>후원 정보</span>
              </th>
              <th>
                <label>
                  <input type="checkbox" v-model="state.check.all" @change="onCheckAllChange()"/>
                  <span>배송 필요</span>
                </label>
              </th>
              <th class="status">
                <span>택배사 송장번호(상태)</span>
              </th>
              <th>
                <span>제공 처리</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(i, idx) in state.investList" :key="idx">
              <td class="num">
                <span>{{ state.paginationInfo.totalRecordCount - ((state.paginationInfo.currentPageNo - 1) * state.paginationInfo.recordCountPerPage + idx) }}</span>
              </td>
              <td class="member">
                <span>{{ i.memberName }}({{ i.memberId }})</span>
              </td>
              <td class="reward">
                <div>
                  <span class="mr-2">{{ i.title }}</span>
                  <span class="mr-2">{{ i.handlingDate }}</span>
                  <span class="mr-2">{{ $lib.getNumberFormat(i.expenseAmt) }}원</span>
                  <span class="mr-2">{{ i.paymethod }}</span>
                </div>
                <router-link :to="`/manage/reward/${$route.params.projectSeq}/investor/${i.investorSeq}`" class="color-anchor font-xs pointer-u">자세히 보기</router-link>
              </td>
              <td>
                <template v-if="i.deliveryYn === 'Y'">
                  <input type="checkbox" :value="i.investorSeq" :checked="i.checkDelivery === 1" disabled/>
                </template>
                <input type="checkbox" :value="i.investorSeq" v-model="state.check.sequences" @change="onCheckChange()" v-else/>
              </td>
              <td class="status" v-if="i.deliveryYn === 'Y'">
                <span v-if="i.checkDelivery === 0">제공 완료</span>
                <span v-else-if="i.checkDelivery === 1">{{ i.viewDeliveryCompany }} &nbsp;/&nbsp; {{ i.trackingNumber }} (배송처리)</span>
              </td>
              <td class="status input" v-else>
                <template v-if="state.check.sequences.includes(i.investorSeq)">
                  <select :id="`${component.name}DeliveryCompany${i.investorSeq}`" class="form-control font-xs border-focus-point" v-model="i.deliveryCompany">
                    <option value="">택배사</option>
                    <option :value="d.codeId" v-for="(d, idx) in state.deliveryList" :key="idx">{{ d.codeIdName }}</option>
                  </select>
                  <Number :id="`${component.name}TrackingNumber${i.investorSeq}`" :value.sync="i.trackingNumber" :allowZero="true" :noComma="true" placeholder="숫자만 입력" :enter="() => { put(i) }"/>
                </template>
              </td>
              <td>
                <a class="pointer text-primary" v-if="i.deliveryYn === 'N'" @click="put(i)">저장</a>
                <a class="pointer text-danger" v-else-if="i.deliveryYn === 'Y'" @click="put(i)">취소</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="page">
          <Pagination :info="state.paginationInfo" :change="load"/>
        </div>
      </template>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import router from "@/scripts/router";
import Pagination from "@/components/Pagination";
import store from "@/scripts/store";
import Number from "@/components/Number";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageManageDelivery";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, Number, Pagination, Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      for (let i = 0; i < state.args.pageUnit; i += 1) {
        state.investList.push({
          memberName: "Please wait a moment",
          memberId: "Please wait a moment",
          title: "Please wait a moment",
          expenseAmt: 0,
          deliveryYn: "Y",
          checkDelivery: 1,
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      check: {
        all: false,
        sequences: []
      },
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 30,
        searchCondition: "memberName",
        searchKeyword: "",
        deliveryYn: ""
      },
      deliveryList: [],
      investList: [],
      investListTotal: 0,
      paginationInfo: {
        totalRecordCount: 0,
        currentPageNo: 0,
        recordCountPerPage: 0,
      },
      simulationFlag: "",
      viewDeliveryProject: "",
    });

    const projectSeq = router.app.$route.params.projectSeq;

    const onCheckChange = () => {
      state.check.all = state.investList.filter(i => i.deliveryYn !== "Y").length === state.check.sequences.length;
    };

    const onCheckAllChange = () => {
      state.check.sequences = [];

      if (state.check.all) {
        for (let i of state.investList) {
          if (i.deliveryYn === "Y" && [0, 1].includes(i.checkDelivery)) {
            continue;
          }

          state.check.sequences.push(i.investorSeq);
        }
      }
    };

    const put = (investor) => {
      const args = {
        deliveryCompany: "",
        deliveryYn: "N",
        trackingNumber: ""
      };

      if (investor.deliveryYn === "N") {
        args.deliveryCompany = investor.deliveryCompany || "";
        args.deliveryYn = "Y";
        args.trackingNumber = investor.trackingNumber || "";

        if (state.check.sequences.includes(investor.investorSeq)) {
          if (!args.deliveryCompany) {
            document.getElementById(`${component.name}DeliveryCompany${investor.investorSeq}`)?.focus();
            return store.commit("setSwingMessage", "택배사를 선택해주세요.");
          } else if (!args.trackingNumber) {
            document.getElementById(`${component.name}TrackingNumber${investor.investorSeq}`)?.focus();
            return store.commit("setSwingMessage", "운송장 번호를 입력해주세요.");
          }
        }
      }

      http.put(`/api/maker/project/reward/${projectSeq}/delivery/${investor.investorSeq}`, args).then(() => {
        investor.checkDelivery = state.check.sequences.includes(investor.investorSeq) ? 1 : 0;
        investor.deliveryCompany = args.deliveryCompany;
        investor.deliveryYn = args.deliveryYn;
        investor.viewDeliveryCompany = state.deliveryList.find(d => d.codeId === args.deliveryCompany)?.codeIdName;
        investor.trackingNumber = args.trackingNumber;

        if (investor.deliveryYn === "N") {
          const idx = state.check.sequences.indexOf(investor.investorSeq);
          idx >= 0 && state.check.sequences.splice(idx, 1);
        }

        store.commit("setSwingMessage", "배송 정보가 업데이트되었습니다.");
      });
    };

    const load = (num) => {
      state.args.pageIndex = num || 1;
      state.check.all = false;
      state.check.sequences = [];

      window.scrollTo(0, 0);

      state.loaded = false;
      http.get(`/api/maker/project/reward/${projectSeq}/delivery`, state.args).then(({data}) => {
        state.loaded = true;
        state.deliveryList = data.body.deliveryList;
        state.investListTotal = data.body.investListTotal;
        state.paginationInfo = data.body.paginationInfo;
        state.investList = [];

        for (let i of data.body.investList) {
          i.deliveryCompany = i.deliveryCompany || "";
          state.investList.push(i);
        }
      });
    };

    return {component, state, onCheckChange, onCheckAllChange, put, load};
  }
});
</script>

<style lang="scss" scoped>
.manage-delivery {
  .alert {
    background: $colorBackground;
    border: $px1 solid #eee;

    div {
      font-weight: 500;
      padding-bottom: $px8;
    }
  }

  .content {
    padding-top: $px15;

    .header {
      padding-bottom: $px15;
      position: relative;

      > .row {
        > .left {
          > select {
            font-size: $px13;
            margin-right: $px15;
            width: $px135;
            height: $formHeight;
          }
        }

        > .right {
          > a {
            float: right;
            box-shadow: none;
            border: $px1 solid #eee;
            margin-left: $px10;
            padding-top: $px13;
            height: $formHeight;
          }
        }
      }

      > .input-group {
        height: $formHeight;
        position: absolute;
        right: $px125;
        top: 0;
        width: $px300;

        > .input-group-prepend {
          width: $px100;
          height: 100%;

          > select {
            font-size: $px13;
            height: 100%;
            border-radius: $px4 0 0 $px4;

            &:focus {
              position: relative;
              z-index: 1;
            }
          }
        }

        > input {
          font-size: $px13;
          height: 100%;
        }

        > .input-group-append {
          height: 100%;

          > .btn {
            border: $px1 solid #dee2e6;
            box-shadow: none;
            height: 100%;

            &[disabled] {
              background: #e9ecef;
              opacity: 1;
            }
          }
        }
      }
    }

    table {
      th, td {
        padding-left: $px15;
        padding-right: $px15;
      }

      th {
        > label {
          margin-bottom: 0;

          > input, > span {
            vertical-align: middle;
          }

          > input {
            margin-right: $px6;
          }
        }

        &.num {
          width: $px70;
        }

        &.member {
          width: $px230;
        }

        &.status {
          width: $px200;
        }
      }

      td {
        white-space: pre-line;

        &.reward {
          > div > span {
            display: inline-block;
          }
        }

        &.input {
          padding: $px5 0 0 0;
          white-space: nowrap;

          select {
            width: $px80;
            display: inline-block;
          }

          input {
            font-size: $px12 !important;
            margin-left: $px4;
            width: $px107;
            display: inline-block;
          }
        }
      }
    }

    .page {
      padding-top: $px15;
    }
  }

  &.skeleton {
    .content table {
      th, td {
        > span, > a {
          @include skeleton;
        }

        > input[type=checkbox] {
          visibility: hidden;
        }
      }

      th > label {
        @include skeleton;

        input[type=checkbox] {
          visibility: hidden;
        }
      }

      td.reward > div > span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    .content {
      .header {
        > .input-group {
          position: static;
          margin-top: $px10;
          width: 100%;
        }
      }

      table {
        th, td {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>