<template>
  <div class="account-withdraw" :class="{skeleton: !state.loaded }">
    <div class="wrapper">
      <div class="title font-md">
        <span>출금하기</span>
      </div>
      <div class="desc font-sm">
        <span>출금 계좌로 요청하신 금액을 이체합니다.</span>
      </div>
      <template v-if="$lib.isValidMobileNum(modalParams.investorTel)">
        <template v-if="state.withdrawalAmount">
          <template v-if="state.withdrawalCount">
            <div class="alert alert-info">
              <span>{{ state.form.token ? "출금 금액을 입력 후 출금하기 버튼을 눌러주세요." : "안전한 서비스 이용을 위해 SMS 인증을 진행해주세요." }}</span>
            </div>
            <div class="form" v-if="!state.form.token">
              <div class="form-group sms mb-4">
                <label :for="`${component.name}Sms`" class="font-sm">
                  <span>SMS 인증</span>
                </label>
                <InvestorPhone :investorSeq="modalParams.investorSeq" :mobileNum="modalParams.investorTel" :token.sync="state.form.token" :skeleton="!state.loaded"/>
              </div>
            </div>
            <template v-if="state.form.token">
              <div class="form">
                <div class="form-group">
                  <label class="font-sm">
                    <span>출금 계좌</span>
                  </label>
                  <input type="text" class="form-control font-sm border-focus-point" :value="`${$definitions.codes.obj.bank[modalParams.withdrawAccountCode]} ${modalParams.withdrawAccountNum}`" disabled/>
                </div>
              </div>
              <div class="form">
                <div class="form-group amount mb-4">
                  <label :for="`${component.name}Amount`" class="font-sm">
                    <span>출금 요청 금액</span>
                  </label>
                  <span class="side font-xs color-secondary" v-if="state.withdrawalCount">{{ $lib.getNumberFormat(state.withdrawalAmount) }}원 출금 가능</span>
                  <button class="btn btn-bordered-secondary font-xs" @click="state.form.amount = state.withdrawalAmount" :disabled="computedDisabled">전액 입력</button>
                  <Number :id="`${component.name}Amount`" :value.sync="state.form.amount" autocomplete="off" :disabled="computedDisabled"/>
                </div>
              </div>
            </template>
          </template>
          <div class="alert alert-warning mb-3 text-center" v-else>
            <span>이번 달 출금 가능 횟수를 초과하였습니다.</span>
          </div>
        </template>
        <div class="alert alert-warning mb-3 text-center" v-else>
          <span>출금 가능한 금액이 없습니다.</span>
        </div>
        <div class="alert guide mb-0">
          <p>
            <span>청약 신청 중인 금액은 출금 가능 금액에 포함되지 않습니다. 출금 가능 횟수는 한 달에 총 4회로 제한되니 출금 시 유의해주세요. 횟수 초과 시 익월 1일부터 출금 가능합니다.</span>
          </p>
          <div>
            <span>전자금융 사기 피해 방지를 위해 지연 출금 정책을 시행하고 있습니다. 입금 후 1시간이 경과하지 않은 예치금은 출금 가능 금액에 포함되지 않습니다.</span>
          </div>
        </div>
      </template>
      <div class="alert alert-warning mb-0" v-else>
        <div>
          <span>보안 상의 이유로 SMS 인증이 필요합니다.</span>
        </div>
        <br/>
        <div>
          <span>투자자의 연락처({{ modalParams.investorTel }})가 휴대전화 번호가 아니어서 SMS 인증을 진행할 수 없습니다.</span>
        </div>
        <div>
          <span>아래의 연락처로 문의하시면 빠르게 처리해드리겠습니다. 이용에 불편을 드려 죄송합니다.</span>
        </div>
        <br/>
        <span>오마이컴퍼니 </span>
        <a href="tel:023882556">
          <span>02-388-2556</span>
        </a>
      </div>
    </div>
    <div class="action" v-if="state.form.token">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="submit()" :disabled="!state.withdrawalAmount || !state.withdrawalCount">
        <span class="txt">출금하기</span>
        <span class="badge font-xs" v-if="state.withdrawalAmount && state.withdrawalCount">{{ state.withdrawalCount }}회 남음</span>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import Phone from "@/components/Phone";
import InvestorPhone from "@/components/InvestorPhone";

function Component(initialize) {
  this.name = "modalAccountWithdraw";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {InvestorPhone, Phone, Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xs"});

      state.loaded = false;
      http.get(`/api/invest/${modalParams.investorSeq}/deposit-account`).then(({data}) => {
        state.loaded = true;
        state.withdrawalCount = (4 - data.body.monthlyWithdrawCount) || 0;
        state.withdrawalAmount = data.body.withdrawalAmount;
      });

      state.balance = modalParams.balance;
    });

    const state = reactive({
      loaded: false,
      withdrawalCount: 0,
      withdrawalAmount: 0,
      form: {
        amount: 0,
        token: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const computedDisabled = computed(() => {
      return !state.loaded || !state.withdrawalAmount || !state.withdrawalCount;
    });

    const submit = () => {
      if (!state.form.amount) {
        document.getElementById(`${component.name}Amount`).focus();
        return store.commit("setSwingMessage", "출금 요청 금액을 입력해주세요.");
      } else if (state.form.amount > state.withdrawalAmount) {
        document.getElementById(`${component.name}Amount`).focus();
        return store.commit("setSwingMessage", "출금 가능 금액보다 큰 금액은 출금할 수 없습니다.");
      }

      store.commit("confirm", {
        message: `총 ${lib.getNumberFormat(state.form.amount)}원을 출금하시겠습니까?`,
        allow() {
          const args = {
            investorSeq: modalParams.investorSeq,
            withdrawAmount: state.form.amount,
            withdrawAccountBankName: definitions.codes.obj.bank[modalParams.withdrawAccountCode],
            token: state.form.token,
          };

          http.put("/api/invest/deposit-account/refund", args).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "출금 신청을 완료하였습니다. 실제 출금까지 1분 정도 소요될 수 있습니다.");
              }
            });
          }).catch(httpError(() => {
            state.form.token = "";
          }));
        }
      });
    };

    return {component, state, modalParams, computedDisabled, submit};
  }
});
</script>

<style lang="scss" scoped>
.account-withdraw {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      margin-bottom: $px20;

      .form-group {
        input[type=text], input[type=tel], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.amount {
          position: relative;
          padding-right: $px70;

          .side {
            position: absolute;
            top: 0;
            right: 0;
            padding-top: $px5;
          }

          input {
            border-radius: $px4 0 0 $px4;

            &:focus {
              position: relative;
            }
          }

          > .btn {
            position: absolute;
            border-color: #ced4da;
            border-left: 0;
            border-radius: 0 $px4 $px4 0;
            right: 0;
            bottom: 0;
            height: $formHeight;
            width: $px70;
            line-height: calc(#{$formHeight} - 2px);
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }

    .alert {
      font-size: $px13;
      padding: $px15;
      white-space: pre-line;

      &.guide {
        background: #e9ecef;
        border: $px1 solid #ced4da;
      }
    }
  }

  .action {
    position: relative;

    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;

      .badge {
        background: #077c60;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: $px-9;
        margin-left: $px59;
        border-radius: $px4 $px4 $px4 0;
        transition: background 0.18s, border-color 0.18s;
      }

      &:hover .badge {
        background: #00b589;
      }
    }
  }

  &.skeleton {
    > .wrapper {
      .alert {
        background: #f7f7f7;
        border-color: #f7f7f7;

        span {
          @include skeleton;
        }
      }

      > .title > span, > .desc > span {
        @include skeleton;
      }

      .form {
        .form-group {
          label > span {
            @include skeleton;
          }

          input[type=text], input[type=tel], input[type=password], select {
            @include skeleton;
          }

          &.amount {
            .side, > .btn {
              @include skeleton;
            }
          }
        }
      }
    }

    .action .btn {
      @include skeleton;

      .badge {
        display: none
      }
    }
  }
}
</style>