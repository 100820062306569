var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reservation-detail",class:{skeleton: !_vm.state.loaded, fixed: _vm.state.fixed}},[_c('div',{staticClass:"summary"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"left col-lg-8"},[_c('div',{staticClass:"image"},[_c('span',{staticClass:"img",style:({backgroundImage: ("url('" + (_vm.state.project.thumbnailPath) + "')")})})])]),_c('div',{staticClass:"right col-lg-4"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"subject font-base"},[_c('span',{staticClass:"badge badge-primary font-xs"},[_vm._v(_vm._s(_vm.state.project.reservationCate === "invest" ? "증권형" : "후원형"))]),_c('div',{staticClass:"txt"},[_c('b',[_vm._v(_vm._s(_vm.state.project.projectName))])])]),(_vm.state.project.hideOpenDateYn !== 'Y')?_c('div',{staticClass:"section"},[_c('i',{staticClass:"fa fa-check color-point"}),_c('b',[_vm._v(_vm._s(_vm.state.project.openDate))]),_c('span',[_vm._v(" 오픈예정")])]):_vm._e(),_c('div',{staticClass:"section"},[_c('i',{staticClass:"fa fa-check color-point"}),_c('b',[_vm._v(_vm._s(_vm.$lib.getNumberFormat(_vm.state.project.applyCnt))+"명")]),_c('span',[_vm._v(" 알림 신청")])])]),_c('MakerInfo',{attrs:{"builderSeq":_vm.state.project.openId || _vm.state.project.memberSeq || _vm.state.project.builderSeq,"projectType":_vm.state.project.reservationCate}}),_c('div',{staticClass:"act join"},[_c('ReservationBtn',{attrs:{"page":"detail","project":_vm.state.project,"share":{
                              link: '/reservation/' + _vm.state.project.reservationSeq,
                              title: _vm.state.project.projectName,
                              imgUrl: _vm.computedThumbnailUrl
                            },"skeleton":!_vm.state.loaded}})],1)],1)])]),_c('div',{ref:"fixedTopRef",staticClass:"fixed-fit"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"name"},[_c('b',[_vm._v(_vm._s(_vm.state.project.projectName))])]),_c('div',{staticClass:"act"},[_c('ReservationBtn',{attrs:{"page":"detail","project":_vm.state.project,"share":{
                              link: '/reservation/' + _vm.state.project.reservationSeq,
                              title: _vm.state.project.projectName,
                              imgUrl: _vm.computedThumbnailUrl
                            },"skeleton":!_vm.state.loaded}})],1)])])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{ref:"contentWrapperRef",staticClass:"wrapper"},[_c('div',{staticClass:"edit-style",domProps:{"innerHTML":_vm._s(_vm.state.project.content)}})])])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"font-sm",attrs:{"to":"/reservation/list"}},[_c('i',{staticClass:"fa fa-angle-left"}),_c('span',[_vm._v("프로젝트 목록으로")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }