<template>
  <div class="detail-community-news board" :class="{skeleton:!state.loaded.list}">
    <div class="latest part" v-if="state.forbidden.code !== '403 FORBIDDEN' || ['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth) || $store.state.account.memberSeq === openId">
      <ul class="tight" v-if="Array.isArray(state.news.list) && state.news.list.length">
        <li class="pointer" :class="{active:n.newsSeq === state.news.detail.newsSeq}" v-for="(n, idx) in state.news.list" :key="idx" @click="state.loaded.list && open(n.newsSeq)">
          <div class="wrapper">
            <span class="title">{{ n.title || "(제목 없음)" }}</span>
            <div class="bottom">
              <span class="date">{{ n.updateDate ? n.updateDate : n.createDate }}</span>
              <span class="color-secondary" v-if="n.commentCount > 0">댓글 {{ $lib.getNumberFormat(n.commentCount) }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div v-else-if="state.loaded">
        <NoData text="등록된 최근소식이 없습니다."/>
      </div>
      <div class="page" v-if="state.paginationInfo && state.paginationInfo.lastPageNo > 1">
        <Pagination :info="state.paginationInfo" :change="load"/>
      </div>
    </div>
    <div v-else class="forbidden">
      <span v-if="$store.state.account.loggedIn">마감된 프로젝트의 최근소식과 Q&A는 투자자와 발행기업의 소통창구로 활용되어, 해당 프로젝트에 배정된 투자자만 확인이 가능합니다.</span>
      <span v-else>
        <span class="login pointer color-anchor" @click="login()">로그인</span>이 필요한 서비스입니다. 로그인 후 이용해주세요.
      </span>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";
import NoData from "@/components/NoData";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageProjectDetailCommunityNews";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Pagination, Loading},
  props: {
    projectSeq: Number,
    projectType: String,
    openId: String,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      pageIndex: 1,
      args: {},
      paginationInfo: null,
      loaded: {
        list: false,
        comments: false
      },
      news: {
        detail: {
          title: "",
          writerName: "",
          updateDate: "",
        },
        list: [],
        comments: []
      },
      forbidden: {
        code: "",
        message: "",
      }
    });

    const detailRef = ref();

    const open = (newsSeq) => {
      store.commit("openModal", {
        name: "News",
        params: {
          projectSeq: props.projectSeq,
          projectType: props.projectType,
          newsSeq: newsSeq,
          page: "detail"
        }
      });

      // state.news.detail = news;
      //
      // if (detailRef && detailRef.value) {
      //   const curScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //
      //   window.scrollTo({
      //     top: curScrollTop + detailRef.value.getBoundingClientRect().top - 70,
      //     left: 0,
      //     behavior: "smooth"
      //   });
      // }
      //
      // loadComments();
    };

    const login = () => {
      store.dispatch("goLoginPage");
    };

    const load = (pageIndex) => {
      state.pageIndex = pageIndex ? pageIndex : 1;
      state.news.list = [];
      state.news.comments = [];
      state.args = {
        pageIndex: state.pageIndex,
        pageUnit: 5,
      };

      for (let i = 0; i < 5; i += 1) {
        state.news.list.push({
          newsSeq: "0",
          title: "Wait a moment",
          updateDate: "0000-00-00"
        });
      }

      state.loaded.list = false;
      http.get(`/api/${props.projectType}/projects/${props.projectSeq}/news`, state.args).then(({data}) => {
        state.loaded.list = true;
        state.paginationInfo = data.body.paginationInfo;
        state.news.list = data.body.newsList;
        state.news.detail.title = "";
        state.news.detail.writerName = "";
        state.news.detail.updateDate = "";
        state.forbidden.code = data.code;
        state.forbidden.message = data.message;
      });
    };

    return {component, state, detailRef, open, login, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community-news {
  .forbidden {
    background: #fff;
    border-radius: $px4;
    width: 100%;
    height: $px150;
    display: flex;
    justify-content: center;
    align-items: center;

    .login {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.detail-community-news {
  &.skeleton {
    .latest ul > li > .wrapper span {
      @include skeleton
    }
  }
}
</style>