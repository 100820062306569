<template>
  <form :id="`${component.name}Form`" class="extract-form" :action="modalParams.url" method="post" target="_blank" @submit.prevent="submit()">
    <div class="wrapper">
      <div class="title font-md">
        <span>{{ modalParams.title }}</span>
      </div>
      <div class="notices" v-if="modalParams.notices &&  modalParams.notices.length">
        <div class="alert alert-secondary font-sm">
          <ul class="mb-0">
            <li v-for="(n, idx) in modalParams.notices" :key="idx">{{ n }}</li>
          </ul>
        </div>
      </div>
      <div class="items" v-show="state.form.items.length">
        <div v-for="(i, idx) in state.form.items" :key="idx">
          <label :for="`${component.name}Item${idx + 1}`" class="font-sm">
            <span class="color-purple" v-if="i.required">* </span>
            <span>{{ i.title }}</span>
          </label>
          <Date :componentNameSuffix="idx.toString()" :id="`${component.name}Item${idx + 1}`" class="font-sm" inputClass="border-focus-purple" :placeholder="i.desc" :value.sync="i.value" v-if="i.type === 'date'"/>
          <div class="form-group form-check font-sm mb-0" v-else-if="i.type === 'checkbox'">
            <input type="checkbox" :id="`${component.name}Checkbox${i}`" class="form-check-input" v-model="i.value"/>
            <label class="form-check-label" :for="`${component.name}Checkbox${i}`">{{ i.desc }}</label>
          </div>
          <select v-model="i.value" class="font-sm form-control" v-else-if="i.type === 'select'">
            <option :value="o.name" v-for="o in i.options" :key="o.name">{{ o.title }}</option>
          </select>
          <Number :id="`${component.name}${i.name}`" :placeholder="i.desc" :value.sync="i.value" v-else-if="i.type === 'number'" className="border-focus-purple"/>
          <input :id="`${component.name}${i.name}`" type="text" class="form-control border-focus-purple" v-model="i.value" autocomplete="off" :placeholder="i.desc" :maxlength="i.maxlength" v-else/>
        </div>
        <input type="hidden" name="params"/>
      </div>
      <div class="color-secondary font-sm pt-2">아래 버튼을 클릭하여 엑셀을 다운로드해주세요.</div>
    </div>
    <div class="action">
      <button type="submit" class="btn btn-purple btn-block font-sm">엑셀 다운로드</button>
    </div>
  </form>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import Date from "@/components/Date";
import lib from "@/scripts/lib";
import Number from "@/components/Number.vue";

function Component(initialize) {
  this.name = "modalExtractForm";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, Date, Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      state.form.items = lib.getRenewed(modalParams.items);

      for (let item of state.form.items) {
        item.type === "select" && (item.value = item.options[0].name);
      }
    });

    const state = reactive({
      form: {
        items: []
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      for (let i = 0; i < state.form.items.length; i += 1) {
        if (state.form.items[i].required && !state.form.items[i].value?.toString().trim()) {
          document.getElementById(`${component.name}Item${i + 1}`)?.focus();
          return store.commit("setSwingMessage", `${state.form.items[i].title} 값을 입력해주세요.`);
        }
      }

      const $form = document.getElementById(`${component.name}Form`);
      $form.querySelector("[name=params]").value = JSON.stringify(state.form.items);
      $form.submit();
    };

    return {component, state, submit, modalParams};
  },
});
</script>

<style lang="scss" scoped>
.extract-form {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px16;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .notices {
      padding-bottom: $px5;

      .alert {
        border: 1px solid #eee;
        margin-bottom: $px10;
        padding: $px15;

        ul {
          padding-left: $px15;
        }
      }
    }

    .items {
      > div {
        padding-bottom: $px15;

        input {
          &:not([type=checkbox]) {
            display: block;
            height: $formHeightLg;
            font-size: $px14;
            border-color: #ddd;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>