<template>
  <div class="detail-cheer">
    <template v-if="!state.cheer.loaded || (state.cheer.loaded && state.cheer.list.length > 0)">
      <div class="comment item" v-for="(m, idx) in state.cheer.list" :key="idx" :class="{skeleton: m.skeleton}" :id="`${component.name}Item`">
        <div class="member">
          <router-link :to="`/channel/${m.memberSeq}`" v-if="m.memberSeq">
            <MemberPicture :memberSeq="m.memberSeq" class="picture" size="35"/>
          </router-link>
          <MemberPicture class="picture" size="35" v-else/>
          <div class="wrapper">
            <router-link :to="`/channel/${m.memberSeq}`" v-if="m.memberSeq">
              <span class="member-name">{{ m.memberName }}</span>
            </router-link>
            <span class="member-name" v-else>{{ m.memberName }}</span>
            <span class="date">{{ m.regDate }}</span>
          </div>
        </div>
        <div class="text">
          <span>{{ m.content }}</span>
        </div>
        <Anchor :href="`/reward/${m.projectSeq}`" class="project clearfix" role="button">
          <div class="thumbnail">
            <div :style="{backgroundImage: `url('${computedThumbnailUrl(m.thumbFileUrl)}')`}"></div>
          </div>
          <div class="info">
            <div class="title ellipsis">
              <span>{{ m.projectName }}</span>
            </div>
            <div class="amount">
              <span>₩{{ $lib.getNumberFormat(m.totalAmt) }} 후원</span>
            </div>
          </div>
        </Anchor>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import router from "@/scripts/router";
import http from "@/scripts/http";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageChannelDetailCheer";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, Anchor, MemberPicture},
  mixins: [mixin],
  props: {
    computedThumbnailUrl: Function,
  },
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      cheer: {
        loaded: false,
        more: false,
        paginationInfo: null,
        list: [],
      },
      args: {
        pageIndex: 1,
        pageUnit: 10,
        pageSize: 5,
      },
      temp: ""
    });

    const load = (more) => {
      if (!more) {
        state.args.pageIndex = 1;
        state.cheer.loaded = false;
      } else {
        state.args.pageIndex += 1;
        state.cheer.more = true;
      }

      for (let i = 0; i < 6; i += 1) {
        state.cheer.list.push({
          projectName: "Wait",
          title: "Please Wait a moment",
          createDate: "0000-00-00",
          memberName: "Please Wait a moment",
          content: "Please Wait a moment",
          skeleton: true,
        });
      }

      http.get(`/api/channel/${router.app.$route.params.makerSeq}/cheers`, state.args).then(({data}) => {
        state.cheer.paginationInfo = data.body.paginationInfo;
        state.cheer.loaded = true;

        if (more) {
          state.cheer.list = state.cheer.list.filter(i => !i.skeleton);

          for (let i in data.body.list) {
            state.cheer.list.push(data.body.list[i]);
          }

          return state.cheer.more = false;
        }

        state.cheer.list = data.body.list;
      });
    };

    const onScroll = () => {
      if (!state.cheer.loaded || state.args.pageIndex >= state.cheer.paginationInfo.lastPageNo || state.cheer.more) {
        return;
      }

      const firstItemHeight = document.getElementById(`${component.name}Item`).offsetHeight;

      firstItemHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.detail-cheer {
  margin: $px25 0;

  .comment {
    padding: $px25 0;
    position: relative;

    .member {
      .picture {
        vertical-align: middle;
      }

      .wrapper {
        display: inline-block;
        font-size: $px12;
        vertical-align: middle;
        padding-left: $px12;

        .date {
          font-weight: 300;
          display: block;
          font-size: $px11;
        }
      }
    }

    .text {
      margin: $px20 0;
      white-space: pre-line;
    }

    .project {
      border: 1px solid #f0f0f0;
      cursor: pointer;
      display: block;
      font-size: $px12;
      position: relative;
      transition: border-color 0.18s;

      > .thumbnail {
        width: $px120;

        > div {
          padding-top: $ratio53Percent;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          width: 100%;
          background-color: #f7f7f7;
        }
      }

      > .info {
        width: calc(100% - $px120);
        padding: 0 $px15;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        .amount {
          font-weight: 300;
          color: $colorPurple;
          margin-top: $px10;
        }
      }

      &:hover {
        border-color: $colorBorder;
        text-decoration: none;
      }
    }

    &.skeleton {
      .member, .project {
        > span, .thumbnail > div, .wrapper > span, .info > div > span {
          @include skeleton;
        }

        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }

      .text {
        @include skeleton;
      }
    }
  }
}
</style>