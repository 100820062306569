<template>
  <div class="invest-order-form" :class="{skeleton: !state.loaded}">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="row">
        <div class="input col-lg-7">
          <div class="wrapper">
            <router-link :to="`/invest/${$route.params.projectSeq}`" class="back font-sm pointer" title="프로젝트 상세로 이동">
              <span class="img" style="background-image: url(/assets/ico/common.back.svg)"></span>
            </router-link>
            <router-link :to="`/invest/${$route.params.projectSeq}`" class="thumbnail img" title="프로젝트 조회" :style="{ 'background-image' : `url(${$definitions.urls.imgCdn + state.project.thumbNailPath})` }"></router-link>
            <header>
              <router-link :to="`/invest/${$route.params.projectSeq}`" class="img" title="프로젝트 조회" :style="{ 'background-image' : `url(${$definitions.urls.imgCdn + state.project.thumbNailPath})` }"></router-link>
              <router-link :to="`/invest/${$route.params.projectSeq}`" class="title font-lg" title="프로젝트 조회">
                <span>{{ state.project.projectName }}</span>
              </router-link>
              <div class="corp font-sm">
                <span class="badge badge-point">{{ state.project.stockTypeText }}</span>
                <span>&nbsp; {{ state.project.corporateName }}</span>
              </div>
            </header>
            <template v-if="state.investInvestor.investorSortCode === '01'">
              <div class="alert alert-warning font-sm mb-30" v-if="state.investInvestorLimit.invstrSorttpCd === '02'">
                <span>이전에 '소득적격투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '03'">
                <span>이전에 '전문투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '04'">
                <span>이전에 '연고자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '05'">
                <span>이전에 '계약전문투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
            </template>
            <template v-else-if="state.investInvestor.investorSortCode === '02'">
              <div class="alert alert-warning font-sm mb-30" v-if="state.investInvestorLimit.invstrSorttpCd === '03'">
                <span>이전에 '전문투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '04'">
                <span>이전에 '연고자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '05'">
                <span>이전에 '계약전문투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
            </template>
            <template v-else-if="state.investInvestor.investorSortCode === '03'">
              <div class="alert alert-warning font-sm mb-30" v-if="state.investInvestorLimit.invstrSorttpCd === '04'">
                <span>이전에 '연고자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
              <div class="alert alert-warning font-sm mb-30" v-else-if="state.investInvestorLimit.invstrSorttpCd === '05'">
                <span>이전에 '계약전문투자자'로 인증받으신 이력이 있습니다. 한도를 늘리시려면 투자자유형을 변경해주세요. </span>
                <router-link class="color-anchor" to="/mypage/investor">투자자 유형 변경</router-link>
              </div>
            </template>
            <div class="parts">
              <div class="part">
                <div class="subject font-md">
                  <span>투자 내용</span>
                </div>
                <div class="content">
                  <table class="table font-sm">
                    <tbody>
                    <tr>
                      <th>
                        <span>투자마감일</span>
                      </th>
                      <td>
                        <span>{{ state.project.fundingEndDate.substring(0, 10) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span>입고예정일</span>
                      </th>
                      <td>
                        <span>{{ state.project.deliveryDate }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span>목표 금액</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.project.totAmount) }}원</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span>최소투자금액</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.project.minAmount) }}원</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="part unit">
                <div class="subject font-md">
                  <span>투자 금액</span>
                  <span class="side">
                    <b>{{ $lib.getNumberFormat(computedFinalAmount) }}원</b>
                  </span>
                </div>
                <div class="content">
                  <div class="row">
                    <div class="form-group col-6">
                      <label class="subject font-sm">구좌당 금액</label>
                      <input type="email" class="form-control border-focus-point font-sm" :value="$lib.getNumberFormat(state.project.stockAmount)" readonly/>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label class="subject font-sm" :for="`${component.name}subscrCount`">구좌 수</label>
                      <div class="input-group">
                        <Number :id="`${component.name}subscrCount`" :value.sync="state.form.subscrCount" :allowZero="true"/>
                        <div class="input-group-append">
                          <button class="btn btn-secondary font-sm" title="감소" @click="increase(-1)">
                            <i class="fa fa-angle-down"></i>
                          </button>
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-secondary font-sm" title="증가" @click="increase(1)">
                            <i class="fa fa-angle-up"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="part account">
                <div class="subject font-md">
                  <span>OMC 계좌</span>
                </div>
                <div class="content">
                  <div class="alert info font-sm" role="alert">
                    <span>계좌에 투자하실 예치금이 입금되어 있어야 합니다.</span>
                    <br/>
                    <span>입금 후 새로고침을 눌러주세요. 1분 정도 소요됩니다.</span>
                  </div>
                  <div class="paper">
                    <BankBook v-if="$store.state.investor.investorSeq" ref="bankBookRef"/>
                  </div>
                </div>
              </div>
              <div class="part register" v-if="state.project.stockStorageType === '01'">
                <label class="subject font-md" :for="`${component.name}SecucoAcopno`">
                  <span>증권 계좌 입력</span>
                </label>
                <div class="content">
                  <div class="input">
                    <select class="form-control border-focus-point font-sm" :id="`${component.name}SecucoAcopno`" v-model="state.form.secucoAcopno" @change="onSecucoAcopnoChange()">
                      <option value="">증권사</option>
                      <option :value="code.key" v-for="(code, idx) in $definitions.codes.arr.securitiesCompany" :key="idx">{{ code.value }}</option>
                    </select>
                    <input type="text" placeholder="(N/A)" class="font-sm form-control border-focus-point" disabled v-if="state.form.secucoAcopno === '20'"/>
                    <Number :id="`${component.name}InvstTrsacNo`" :value.sync="state.form.invstTrsacNo" :allowZero="true" :noComma="true" placeholder="계좌번호(숫자만 입력해주세요)" v-else/>
                    <button class="btn btn-light font-sm" @click="openRecentAccounts()">최근 거래 계좌</button>
                  </div>
                  <div class="alert info font-sm">
                    <p>
                      <span>펀딩 성공 시 증권을 입고받으실 증권계좌를 등록해주세요. 증권계좌를 준비하지 못하신 경우, "증권계좌없음"을 선택해주세요.</span>
                    </p>
                    <p>
                      <span>※ 청약이 종료되기 전까지 증권 입고 가능한 증권계좌가 준비되지 않으면 청약이 취소됩니다.</span>
                    </p>
                    <p>
                      <span>※ 반드시 아래의 증권사 중 한 곳의 증권계좌를 입력해주세요.</span><br/>
                      <span>- 증권형 크라우드펀딩 업무 참가 증권사 (2020년 11월 기준): 교보증권, 신한투자증권, 한국투자증권, 대신증권, 미래에셋증권, 신영증권, 유진투자증권, 메리츠증권, NH투자증권, KB증권, 현대차증권, 유안타증권, SK증권, 상상인증권, 삼성증권, DB금융투자, 하이투자증권, 키움증권, IBK투자증권, 다올투자증권, 코리아에셋투자증권</span>
                    </p>
                    <div>
                      <span>※ 증권계좌 정보 수정은 <b>마이페이지 > 펀딩 프로젝트 > 증권형</b> 에서 가능합니다.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay col-lg-5">
          <div class="wrapper">
            <div class="outer">
              <div class="inner">
                <div class="subject font-lg">
                  <span>투자 정보</span>
                </div>
                <div class="details">
                  <table class="big table">
                    <tbody>
                    <template v-if="state.project.fundType === '01'">
                      <tr>
                        <th>
                          <span>유효 기간</span>
                        </th>
                        <td>
                          <span v-if="['01', '04'].includes(state.investInvestor.investorSortCode)">유효 기간 없음</span>
                          <span v-else-if="['02', '03'].includes(state.investInvestor.investorSortCode)">
                          <b>{{ state.investInvestor.expiryEndDate }}까지</b>
                          <div>
                            <router-link to="/mypage/investor" class="color-anchor font-sm mt-1" v-if="state.investInvestor.expireFlag === '1'">갱신하기</router-link>
                          </div>
                        </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span>연간 투자 한도</span>
                        </th>
                        <td>
                          <span v-if="state.investInvestor.investorSortCode === '04'"><!-- 제한 없음 --></span>
                          <span v-else-if="state.investInvestor.investorSortCode === '02'">2,000만원</span>
                          <span v-else-if="['03','05'].includes(state.investInvestor.investorSortCode)">제한 없음</span>
                          <span v-else>1,000만원</span>
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <th>
                        <span>나의 투자 한도</span>
                      </th>
                      <td v-if="state.project.fundType === '02' || state.investInvestorLimit.investableAmt === constants.LIMIT_MONEY_SPECIAL">
                        <span>제한 없음</span>
                      </td>
                      <td v-else>
                        <template v-if="state.investInvestorLimit.resultCd === '0000'">
                          <span v-if="['03','04', '05'].includes(state.investInvestor.investorSortCode)">제한없음</span>
                          <span v-else>{{ $lib.getNumberFormat(state.investInvestorLimit.investableAmt) }}원</span>
                        </template>
                        <span v-else-if="state.investInvestorLimit.resultCd === '1000'">업무 마감</span>
                        <span v-else-if="state.investInvestorLimit.resultCd === '0012'">한도 초과(더 이상 투자할 수 없음)</span>
                        <span v-else>시스템 오류</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <template v-if="state.project.fundType === '01'">
                    <div class="alert info font-sm text-center" role="alert">
                    <span>
                      <span>{{ state.investInvestor.investorName }}님은&nbsp;</span>
                      <b>{{ state.investInvestor.investorSortCodeText }}</b>
                      <span>입니다.</span>
                    </span>
                    </div>
                    <table class="small table font-xs">
                      <thead>
                      <tr>
                        <th>
                          <span>투자자 유형</span>
                        </th>
                        <th>
                          <span>연간 한도</span>
                        </th>
                        <th>
                          <span>동일발행인 한도</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <span>일반투자자</span>
                        </td>
                        <td>
                          <span>1,000만원</span>
                        </td>
                        <td>
                          <span>500만원</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>적격투자자</span>
                        </td>
                        <td>
                          <span>2,000만원</span>
                        </td>
                        <td>
                          <span>1,000만원</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>전문투자자</span>
                        </td>
                        <td>
                          <span>제한 없음</span>
                        </td>
                        <td>
                          <span>제한 없음</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>연고자 및<br/>자문계약전문가</span>
                        </td>
                        <td>
                          <span>제한 없음</span>
                        </td>
                        <td>
                          <span>제한 없음</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </template>
                </div>
                <div class="subject font-lg clearfix">
                  <span class="float-left">투자 금액</span>
                  <span class="float-right">
                    <b class="color-point">{{ $lib.getNumberFormat(state.project.stockAmount * state.form.subscrCount) }}</b>
                    <span class="color-point">원</span>
                  </span>
                </div>
              </div>
              <div class="guide font-sm">
                <div class="form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check1`"/>
                    <label class="form-check-label" :for="`${component.name}Check1`">투자 위험의 주지 및 고지</label>
                    <a class="pointer-u float-right" @click="$store.commit('openModal', { name: 'Terms', params: { name: 'investWarning' } })">내용 보기</a>
                  </div>
                </div>
                <div class="form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check2`"/>
                    <label class="form-check-label" :for="`${component.name}Check2`">서비스 이용약관 동의</label>
                    <a class="pointer-u float-right" @click="$store.commit('openModal', { name: 'Terms', params: { name: 'investTerms' } })">내용 보기</a>
                  </div>
                </div>
                <div class="form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check3`"/>
                    <label class="form-check-label" :for="`${component.name}Check3`">개인정보처리방침 동의</label>
                    <a class="pointer-u float-right" @click="$store.commit('openModal', { name: 'Terms', params: { name: 'investPrivacy' } })">내용 보기</a>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-block btn-point font-lg" @click="submit()">투자하기</button>
          </div>
        </div>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import NoLogin from "@/components/NoLogin";
import BankBook from "@/components/BankBook";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import {useGtm} from "@gtm-support/vue2-gtm";
import track from "@/scripts/track";

function Component(initialize) {
  this.name = "pageInvestOrderForm";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {BankBook, NoLogin, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      projectSeq = router.app.$route.params.projectSeq;

      if (!await store.getters.investProjectAvailable("order")) {
        return router.replace(`/invest/${projectSeq}`);
      }

      store.commit("checkInvestor");

      http.get(`/api/invest/project/${projectSeq}/subscribe/auth`).then(({data}) => {
        if (!data.body.investInvestor.virtualAccountNum) {
          store.commit("setSwingMessage", "출금 계좌를 등록해야 합니다. 투자 계좌 페이지로 이동합니다.");
          router.push({path: "/mypage/investor-account-info"});
        } else if (data.body.isExcludedInvestor) {
          store.commit("setSwingMessage", "이 프로젝트의 투자 불가 대상자입니다. 투자하실 수 없습니다.");
          return moveBack();
        } else if (data.body.isPassedTest) {
          constants.LIMIT_MONEY_SPECIAL = data.body.LIMIT_MONEY_SPECIAL;

          if (data.body.investInvestorLimit) {
            state.investInvestorLimit = data.body.investInvestorLimit;

            if (state.investInvestorLimit?.resultCd === "0012") {
              // * 투자자 유형에 따른 한도 모달 띄우기
              store.commit("setSwingMessage", "투자 한도를 초과하셨습니다.");
              return moveBack();
            }
          }

          state.investInvestor = data.body.investInvestor;
          load();
        } else {
          store.commit("setSwingMessage", "증권형 펀딩에 참여하시려면 적합성 테스트를 통과하셔야 합니다.");
          store.commit("openModal", {
            name: "CompatibilityTest",
            params: {projectSeq},
            callback: "refresh",
            preventClose: true,
            routerReplace: true
          });
        }
      }).catch(httpError((err) => {
        switch (err?.response?.status) {
          case 401:
            err?.response?.data?.code !== "LOGIN_REQUIRED" && router.push({path: "/mypage/investor"});
            return;

          case 409:
            return moveBack();
        }
      }));
    });

    const state = reactive({
      loaded: false,
      project: {
        projectName: "Wait a moment",
        stockTypeText: "wait",
        corporateName: "Wait a moment",
        fundingEndDate: "Wait a moment",
        deliveryDate: "Wait a moment",
        totAmount: "0000000",
        minAmount: "0000000",
        stockAmount: 0,
        thumbNailPath: "",
        stockKindText: "",
        faceValue: "",
        stakeRatePerUnit: "",
        couponRate: "",
        interestPeriod: "",
        interestDayCnt: "",
        fundType: "01"
      },
      form: {
        subscrCount: 1,
        secucoAcopno: "",
        invstTrsacNo: "",
      },
      investInvestor: {
        investorName: "",
        investorSortCode: "01",
        investorSortCodeText: "Wait a moment"
      },
      investInvestorLimit: {
        resultCd: "",
        investableAmt: 0,
        invstrSorttpCd: "",
      },
    });

    const bankBookRef = ref();

    const computedFinalAmount = computed(() => {
      return state.project.stockAmount * state.form.subscrCount;
    });

    const constants = {
      LIMIT_MONEY_SPECIAL: 0
    };

    const onSecucoAcopnoChange = () => {
      if (state.form.secucoAcopno === "20") {
        state.form.invstTrsacNo = "0000000000";
      } else {
        if (state.form.invstTrsacNo === "0000000000") {
          state.form.invstTrsacNo = "";
        }

        nextTick(() => {
          document.getElementById(`${component.name}InvstTrsacNo`)?.focus();
        });
      }
    };

    const increase = (value) => {
      state.form.subscrCount = window.Number(state.form.subscrCount) + value;

      if (state.form.subscrCount <= 0) {
        state.form.subscrCount = 1;
      }
    };

    let projectSeq;

    const getInvestAvailableSubscribeCount = () => {
      return Math.floor(state.investInvestorLimit.investableAmt / state.project.stockAmount);
    };

    const isLimitExcess = () => {
      return state.project.fundType !== "02" && ["01", "02"].includes(state.investInvestor.investorSortCode)
          && state.investInvestorLimit.investableAmt !== constants.LIMIT_MONEY_SPECIAL
          && state.investInvestorLimit.investableAmt > 0
          && state.form.subscrCount > getInvestAvailableSubscribeCount();
    };

    const submit = async () => {
      const args = lib.getRenewed(state.form);

      if (computedFinalAmount.value < state.project.minAmount) {
        return store.commit("setSwingMessage", "투자 금액이 최소 투자 금액 이상이어야 합니다.");
      }

      const account = bankBookRef.value.state.account;

      if (account.depositTotalAmount < computedFinalAmount.value) {
        return store.commit("setSwingMessage", "OMC 계좌 잔고가 부족합니다. OMC 계좌에 예치금을 입금해주세요.");
      }

      if (isLimitExcess()) {
        return store.commit("setSwingMessage", "투자 한도를 초과한 금액입니다.");
      }

      if (state.project.stockStorageType === "01") {
        if (!args.secucoAcopno) {
          document.getElementById(`${component.name}SecucoAcopno`)?.focus();
          return store.commit("setSwingMessage", "증권사를 선택해주세요.");
        } else if (args.secucoAcopno !== "20" && !args.invstTrsacNo) {
          document.getElementById(`${component.name}InvstTrsacNo`)?.focus();
          return store.commit("setSwingMessage", "증권 계좌 번호를 선택해주세요.");
        } else if (args.secucoAcopno !== "20" && !lib.isNumeric(args.invstTrsacNo)) {
          document.getElementById(`${component.name}InvstTrsacNo`)?.focus();
          return store.commit("setSwingMessage", "증권 계좌 번호는 숫자만 입력할 수 있습니다.");
        }
      } else {
        args.secucoAcopno = "0000";
      }

      if (!document.getElementById(`${component.name}Check1`).checked) {
        return store.commit("setSwingMessage", "투자 위험의 주지 및 고지에 대한 동의가 필요합니다.");
      } else if (!document.getElementById(`${component.name}Check2`).checked) {
        return store.commit("setSwingMessage", "서비스 이용약관 동의가 필요합니다.");
      } else if (!document.getElementById(`${component.name}Check3`).checked) {
        return store.commit("setSwingMessage", "개인정보처리방침 동의가 필요합니다.");
      } else if (!bankBookRef.value) {
        return store.commit("setSwingMessage", "잠시 후 다시 시도해주세요.");
      }

      const res1 = await http.get(`/api/invest/project/${projectSeq}/subscribe/is-investment-available-date`).catch(httpError());

      if (res1?.error) {
        return;
      } else if (!res1.data.body) {
        return store.commit("setSwingMessage", "현재는 청약이 불가한 시간입니다.");
      }

      const subscribeUrlPath = `/api/invest/project/${projectSeq}/subscribe`;
      args.invstrSortCd = state.investInvestor.investorSortCode;

      const res2 = await http.post(subscribeUrlPath, args).catch(httpError());

      if (res2?.error) {
        return;
      }

      await router.push({path: `/invest/${projectSeq}/done/${res2.data.body.subscribeSeq}`});

      track.post({
        name: "investProjectSubscribe",
        category: "투자하기",
        topic: "투자 완료",
        title: state.project.projectName,
        urlPath: subscribeUrlPath,
        type: "api",
        httpMethod: "POST",
      });
    };

    const load = async () => {
      state.loaded = false;
      const res1 = await http.get(`/api/invest/projects/${projectSeq}`).catch(httpError());
      state.loaded = true;

      if (res1?.error) {
        return;
      }

      state.project = res1.data.body.project;
      state.form.subscrCount = window.Number(state.project.minCount);

      if (Array.isArray(res1.data.body.thumbNailList) && res1.data.body.thumbNailList.length) {
        state.project.thumbNailPath = res1.data.body.thumbNailList[0]?.thumbFilePath;
      }

      track.post({
        name: "investProjectOrderForm",
        category: "투자하기",
        topic: "투자 정보 입력",
        title: state.project.projectName,
      });

      // GTM으로 이벤트 전달
      const loggingMsg = "Event investProject InitiateCheckout";

      try {
        const gtm = useGtm();
        if (gtm.enabled()) {
          const dataLayerProjectName = state.project.projectName;
          // 증권형인 경우 최소투자금액 * 최소투자단위를 상품금액으로 전달
          const dataLayerAmount = state.project.minAmount * state.project.minCount;
          const dataLayerEvent = "EventInvestProjectInitiateCheckout";

          window.dataLayer?.push({
            event: dataLayerEvent,
            "project.projectSeq": projectSeq,
            "project.projectName": dataLayerProjectName,
            "amount": dataLayerAmount,
          });
        }
      } catch (err) {
        // 데이터 전송이 실패하면 콘솔로그출력
        console.warn(`Failed ${loggingMsg} - ${err}`);
      }
    };

    const moveBack = () => {
      store.dispatch("moveBack", `/invest/${projectSeq}`);
    };

    const select = ({secucoAcopno, invstTrsacNo}) => {
      state.form.secucoAcopno = secucoAcopno;
      state.form.invstTrsacNo = invstTrsacNo;
    };

    const openRecentAccounts = () => {
      store.commit("openModal", {
        name: "RecentAccounts",
        params: {investSeq: projectSeq},
        callback: `${component.name}.onSelectRecentAccounts`,
      });
    };

    const onSelectRecentAccounts = ({secucoAcopno, invstTrsacNo}) => {
      state.form.secucoAcopno = secucoAcopno;
      state.form.invstTrsacNo = invstTrsacNo;
    };

    watch(() => state.form.subscrCount, () => {
      if (isLimitExcess()) {
        state.form.subscrCount = getInvestAvailableSubscribeCount();
        document.getElementById(`${component.name}subscrCount`).value = state.form.subscrCount;
        store.commit("setSwingMessage", "투자 한도를 초과한 금액입니다.");
      }
    });

    return {component, state, bankBookRef, computedFinalAmount, constants, onSecucoAcopnoChange, increase, submit, openRecentAccounts, select, onSelectRecentAccounts};
  }
});
</script>

<style lang="scss" scoped>
.invest-order-form {
  padding: $px39 $px15 $px150 $px15;

  .form-control {
    height: $formHeight;
  }

  .alert.info {
    margin: 0;
    border: $px1 solid #eee;
    background: $colorBackground;
    padding: $px18;
  }

  > .container > .row {
    > .input {
      > .wrapper {
        .back {
          display: inline-block;
          text-decoration: none;
          margin-bottom: $px15;

          .img {
            margin-right: $px7;
            width: $px16;
            height: $px16;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
          }

          &:hover {
            span {
              font-weight: 500;
            }
          }
        }

        > .thumbnail {
          position: absolute;
          top: $px10;
          right: $px15;
          width: $px160;
          height: $px96;
          background-color: $colorBackground;
          border: $px1 solid #eee;
          border-radius: $px4;
        }

        header {
          padding-right: $px170;
          padding-bottom: $px35;
          margin-bottom: $px35;
          border-bottom: $px1 solid #eee;

          .title {
            display: inline-block;
            font-weight: 600;
            margin-bottom: $px3;
            text-decoration: none;
          }

          .corp {
            margin-top: $px5;
          }
        }

        .info {
          table {
            margin-top: $px5;
          }
        }

        .parts {
          .part {
            padding-bottom: $px25;
            margin-bottom: $px25;
            position: relative;

            > .subject {
              margin-bottom: $px13;
              font-weight: 600;

              .desc {
                font-weight: normal;
                margin-left: $px8;
                color: #767676;
              }

              .side {
                display: inline-block;
                position: absolute;
                top: $px1;
                right: 0;
                font-weight: normal;

                button {
                  margin-top: $px-2;
                }
              }
            }

            .content {
              table {
                border-bottom: $px1 solid #eee;

                th, td {
                  border-top-color: #eee;
                }

                th {
                  font-weight: normal;
                }

                td {
                  text-align: right
                }
              }

              .form-group {
                margin-bottom: 0;
              }

              .title {
                margin-top: $px13;
                background: $colorBackground;
                padding: $px9 $px15;
                border: $px1 solid #eee;
                border-radius: $px4;

                span {
                  display: inline-block;
                }
              }
            }

            &.unit {
              .content .input-group {
                input {
                  text-align: center
                }

                button {
                  width: $px37;
                  border-left: $px1 solid #aaa;
                }
              }
            }

            &.account {
              .paper {
                padding-top: $px15;

                .section {
                  padding: $px15 0;

                  .subject {
                    color: rgba(255, 255, 255, 0.58);
                    margin-bottom: $px4;
                  }

                  .value {
                    b, span {
                      vertical-align: middle;
                    }

                    button {
                      padding: $px8 0;
                      margin-top: $px-5;
                      float: right;
                      width: $px75;
                    }
                  }
                }
              }
            }

            &.register {
              .content {
                .input {
                  padding: 0 $px120 0 $px135;
                  position: relative;
                  margin-bottom: $px20;

                  select {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: $px125;
                  }

                  button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: $px110;
                    padding-left: 0;
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .pay {
      padding-left: $px66;

      > .wrapper {
        position: sticky;
        top: 0;
        max-width: $px470;
        margin: 0 auto;

        > .outer {
          border: $px1 solid #eee;
          border-radius: $px4;

          .inner {
            padding: $px25 $px25 $px10 $px25;

            .subject {
              margin-bottom: $px15;
              font-weight: 600;
            }

            .details {
              margin-bottom: $px25;

              table {
                &.big {
                  margin-bottom: $px15;

                  th, td {
                    border: 0;
                    padding: $px5 0;
                  }

                  th {
                    font-weight: normal;
                  }

                  td {
                    text-align: right;
                  }
                }

                &.small {
                  margin-top: $px20;
                  border-bottom: $px1 solid #eee;

                  th, td {
                    padding: $px10 $px5;
                    text-align: center;
                    vertical-align: middle;
                    border-color: #eee;
                  }

                  th {
                    border-bottom-width: $px1;
                  }

                  td {
                  }
                }
              }

              .info > span {
                > b, > span {
                  display: inline-block;
                }
              }
            }
          }

          .guide {
            border-top: $px1 solid #eee;
            padding: $px25 $px20;
            background: $colorBackground;

            .form-check:not(:first-child) {
              margin-top: $px7;
            }
          }
        }

        button {
          margin-top: $px24;
          padding: $px26 $px15;
        }
      }
    }
  }

  &.skeleton::v-deep {
    .form-control, button {
      @include skeleton;
    }

    .alert {
      @include skeleton;

      > span, > a {
        @include skeleton;
      }
    }

    .form-check {
      padding-left: 0;

      input {
        display: none
      }

      > div, > label {
        @include skeleton;
      }
    }

    > .container > .row {
      > .input {
        .back {
          @include skeleton;

          .img {
            visibility: hidden;
          }
        }

        header {
          .img {
            @include skeleton;
          }

          .title > span {
            @include skeleton;
          }

          .corp > span {
            @include skeleton;
          }
        }

        .parts .part {
          .subject {
            > span {
              @include skeleton;
            }

            .side {
              min-width: $px80;

              b {
                visibility: hidden;
              }
            }
          }

          .content {
            table {
              th, td {
                span {
                  @include skeleton;
                }
              }
            }

            .title {
              @include skeleton;
            }

            .form-group .subject {
              @include skeleton;
            }
          }

          &.unit .content .input-group button {
            border-color: transparent;
          }

          &.account {
            .content .paper {
              background: $colorBackground;

              .subject > span {
                @include skeleton;
              }

              .value > span {
                @include skeleton;
              }
            }
          }
        }
      }

      .pay {
        > .wrapper {
          border-color: #eee;

          .inner {
            .subject {
              > span {
                @include skeleton;

                span, b {
                  visibility: hidden;
                }
              }
            }

            .details table {
              &.big {
                th, td {
                  span, a {
                    @include skeleton;
                  }
                }
              }

              &.small {
                th, td {
                  span {
                    @include skeleton;
                  }
                }
              }
            }
          }

          .guide {
            p {
              @include skeleton;
            }
          }
        }

        button {
          .img {
            display: none;
          }
        }
      }
    }
  }

  @media(min-height: 768px) {
    > .container > .row > .input > .wrapper .parts .part:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @media(max-height: 767px) {
    > .container > .row > .pay > .wrapper {
      position: static;
    }
  }

  @media(max-width: 1199px) {
    > .container > .row > .pay {
      padding-left: $px30;
    }
  }

  @media(max-width: 991px) {
    padding-bottom: $px39;

    > .container > .row {
      > .input > .wrapper {
        header {
          padding-right: $px130;
        }

        .parts .part {
          > .subject .desc {
            display: block;
            margin-left: 0;
            margin-top: $px5;
          }

          .content .form-group:first-child {
            margin-bottom: $px20;
          }

          &.payment .content ul {
            text-align: center;
          }
        }
      }

      > .pay {
        padding-left: $px15;
      }
    }
  }

  @media(max-width: 767px) {
    > .container > .row > .input > .wrapper {
      > .thumbnail {
        top: $px25;
        width: $px120;
        height: $px72;
      }

      .parts .part.register .content .input {
        padding: 0;

        select {
          width: 100%;
          height: $formHeight;
          position: static;
        }

        input {
          margin: $px10 0;
        }

        button {
          position: static;
          width: 100%;
          height: $formHeight;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>