<template>
  <div class="message">
    <div class="wrapper">
      <div class="desc">
        <router-link :to="`/channel/${getMemberSeq()}`" v-if="!(state.message.memberAuth && state.message.memberAuth.includes('ADMIN'))">
          <span class="subject font-sm"><b>{{ state.message.memberName }}</b></span>
          <span class="date font-xs">({{ state.message.sendDate }})</span>
          <MemberPicture :memberSeq="getMemberSeq()" size="20"/>
        </router-link>
        <template v-else>
          <span class="subject font-sm"><b>오마이컴퍼니</b></span>
          <span class="date font-xs">({{ state.message.sendDate }})</span>
          <MemberPicture :memberSeq="getMemberSeq()" size="20"/>
        </template>
      </div>
      <div class="title font-md">
        <b>{{ state.message.title }}</b>
      </div>
      <div class="content font-sm">{{ state.message.content }}</div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="reply" :class="[state.type === 'inbox' ? 'col-6' : 'col']">
          <button class="btn btn-point btn-block font-sm" @click="reply()">
            <span>{{ state.type === "outbox" ? "다시 보내기" : "답장하기" }}</span>
          </button>
        </div>
        <div class="keep col-6" v-if="state.type === 'inbox'">
          <button class="btn btn-secondary btn-block font-sm" @click="keep()">보관하기</button>
        </div>
      </div>
      <div class="remove">
        <button class="btn btn-block font-sm" @click="remove()" title="삭제">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalMessageDetail";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      if (modalParams) {
        state.message = modalParams.message;
        state.type = modalParams.type;
      }
    });

    const state = reactive({
      type: "",
      message: {
        messageSeq: null,
        title: "",
        content: "",
        memberName: "",
        sendMemberSeq: "",
        takeMemberSeq: "",
        memberImageUrl: "",
        memberAuth: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const getMemberSeq = () => {
      if (state.type === "outbox") {
        return state.message.takeMemberSeq;
      }

      return state.message.sendMemberSeq;
    };

    const reply = () => {
      store.commit("openModal", {
        name: "MessageWrite",
        params: {
          type: state.type,
          members: [{
            seq: state.type === "inbox" ? state.message.sendMemberSeq : state.message.takeMemberSeq,
            name: state.message.memberName,
          }]
        },
        callback: "refresh"
      });
    };

    const keep = () => {
      http.put("/api/messages/draft/" + state.message.messageSeq).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "보관함으로 이동하였습니다.");
          }
        });
      }).catch(httpError());
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete("/api/messages/delete/" + state.message.messageSeq, {messageType: state.type}).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "메시지를 삭제하였습니다.");
              }
            });
          });
        }
      });
    };

    return {component, state, getMemberSeq, reply, keep, remove};
  },
});
</script>

<style lang="scss" scoped>
.message {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      padding: $px10 0 $px20 0;

      span {
        color: #767676;
        margin-left: $px2;
      }
    }

    .desc {
      color: #666;
      position: relative;
      padding-left: $px27;
      display: flex;
      align-items: center;

      > a {
        display: flex;
        align-items: center;
      }

      .subject {
        margin-right: $px4;
      }

      .date {
        float: right;
        min-width: $px70;
        padding-top: $px1;
      }

      .img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .content {
      white-space: pre-line;
    }
  }

  .actions {
    padding-right: $px56;
    position: relative;

    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }

    .row {
      > div {
        &.reply {
          padding-right: 0;
        }

        &.keep {
          padding-left: 0;
        }
      }
    }

    .remove {
      padding-left: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: $px56;
      height: 100%;

      .btn {
        background: #7a7a7a;
        color: #fff;
        height: 100%;

        &:hover {
          background: #626262;
        }
      }
    }
  }

  @media(max-width: 767px) {
    > .wrapper .desc .date {
      float: none;
      display: inline-block;
    }
  }
}
</style>