<template>
  <div class="faq thin-scrollbar">
    <span class="title font-md">자주 묻는 질문</span>
    <div class="accordion" :id="`${component.name}Accordion`">
      <div class="item" v-for="(f, idx) in faqList" :key="idx">
        <div class="question" :id="`${component.name}Parent${idx}`">
          <button class="btn btn-block" type="button" data-toggle="collapse" :data-target="`#${component.name}Child${idx}`" :aria-controls="`#${component.name}Child${idx}`" aria-expanded="true">
            <span class="mr-2">Q. </span>
            <span>{{ f.question }}</span>
          </button>
        </div>
        <div :id="`${component.name}Child${idx}`" class="collapse" :aria-labelledby="`${component.name}Parent${idx}`" :data-parent="`#${component.name}Accordion`">
          <div class="answer">
            <span class="mr-2">A. </span>
            <div>
              <span v-html="f.answer"></span>
              <span v-if="f.url" class="color-point pointer reference" @click="openPhoto(f.url)">{{ f.urlTitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalFaq";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
    });

    const state = reactive({});

    const openPhoto = (filePath) => {
      store.commit("openModal", {
        name: "ReviewPhotoSlider",
        params: {
          photos: [{
            filePath
          }]
        },
      });
    };

    const faqList = [
      {
        question: "정산에 동의한 후 실제 입금까지 얼마나 소요되나요?",
        answer: "계좌정보를 입력한 후 정산 요청까지 마친 시점을 기준으로 <b class='color-point'>약 일주일 정도 소요되며, 매주 화요일, 목요일 오전에 입금이 진행됩니다.</b>",
      }, {
        question: "모집 금액과 정산 금액이 달라요.",
        answer: "펀딩 방식을 먼저 확인해 주세요. \n 펀딩 방식이 <b class='color-point'>성공해야 리워드인 경우</b>에는 펀딩 종료 후 예약 결제를 총 5차례 진행하며, 후원자분들이 카드를 분실했거나 카드와 연결되어 있는 계좌에 잔액이 부족하면 <b class='color-point'>최종 결제가 되지 않아 모집 금액과 정산 금액이 다를 수 있습니다.</b>",
      }, {
        question: "정산받는 금액의 회계처리는 어떻게 하나요?",
        answer: "프로젝트 모집금액은 현금이체로 정산 받으실 수 있으며 기업에서 회계 처리는 <b class='color-point'>상품 및 서비스에 대한 '증빙이 없는 일반현금매출'</b>로 처리하시면 됩니다.\n또한, 프로젝트 진행자는 별도의 세금계산서를 발행하실 필요가 없으며, 오마이컴퍼니에서 펀딩 수수료(플랫폼 이용 수수료 + PG 결제 수수료)에 관한 세금계산서를 진행자에게 발행하게 됩니다.",
      }, {
        question: "후원자 목록은 어디에서 확인할 수 있나요?",
        answer: "후원자 정보는 정산서 파일의 두 번째 탭 ‘후원자 목록’에서 확인하실 수 있습니다.\n또한, 오마이컴퍼니 홈페이지 로그인 > 마이페이지 > 진행 프로젝트 > 관리 > 후원자 관리 탭에서도 확인하실 수 있습니다.",
      }, {
        question: "리워드 제공 후 제공 처리는 어떻게 하나요?",
        answer: "리워드 제공이 완료되면 로그인 > 마이페이지 > 진행 프로젝트 > 관리(톱니바퀴 아이콘) > 후원자 관리 > 리워드 제공 관리 제공 처리를 진행해주세요.",
        urlTitle: "제공 처리 이미지로 보기",
        url: "/assets/img/page.manage.calculate.reward.delivery.guide.popup.jpg",
      },
      {
        question: "사업자등록증(개인신분증)/통장사본을 잘못 업로드 했을 때는 어떻게 하나요?",
        answer: "<b class='color-point'>정산 동의 후 나타나는 화면</b>에서 사업자등록증(개인신분증)/통장사본을 수정할 수 있습니다."
      },
      {
        question: "통장 사본은 어디에서 올릴 수 있나요?",
        answer: "통장 사본은 프로젝트를 정산할 때 <b class='color-point'>정산 동의 후 업로드</b>하실 수 있습니다."
      },
      {
        question: "예전에 정산 받은 프로젝트인데 송금 금액 등이 전부 0원입니다. 어떻게 된 건가요?",
        answer: "오마이컴퍼니의 정산 방식이 바뀌기 전 정산을 받으신 프로젝트들은 정산 데이터를 가지고 있지 않아 그렇게 나올 수 있습니다.\n<b class='color-point'>정산서 다운로드를 클릭</b>하여 당시 정산받은 내역을 엑셀로 받아보실 수 있습니다."
      }
    ];

    return {component, state, faqList, openPhoto};
  }
});
</script>

<style lang="scss" scoped>
.faq {
  padding: $px25;
  background: #fff;
  overflow: auto;
  width: 100%;
  text-align: left;
  max-height: $px600;

  .title {
    margin-bottom: $px17;
    display: block;
  }

  .item {
    border-radius: $px4;
    background: $colorBackground;
    padding: $px15;

    .question {
      .btn {
        display: flex;
        font-size: $px16;
        padding: 0;
        text-align: left;
        will-change: initial;
      }
    }

    .answer {
      display: flex;
      font-size: $px14;
      padding: 0 0 0 $px3;
      text-align: left;
      white-space: pre-line;
      margin-top: $px10;

      .reference {
        display: block;
      }
    }

    &:not(:last-child) {
      margin-bottom: $px10;
    }
  }
}
</style>